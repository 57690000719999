export const getRequiredSignatures = (transaction, activeEnvironment) => {
    const { environmentSignaturesLevels } = activeEnvironment;
    const { featureActivities } = activeEnvironment;
    const { idActivity } = transaction;
    const { userSignatureLevel } = activeEnvironment;
    const sf = featureActivities
        ? Object.keys(featureActivities).find((item) => featureActivities[item].some((value) => value === idActivity))
        : null;
    const idProduct =
        transaction?.products && transaction.products.length > 0 ? transaction.products[0].idProduct : null;
    // Se filtran los esquemas de firmas por el tipo de firma para esta transaccion
    const signatureType = transaction?.totalAmount !== 0 ? "AMOUNT" : "NO_AMOUNT";
    const signaturesSchemesForSignatureType = environmentSignaturesLevels.filter(
        (scheme) => scheme.signatureType === signatureType,
    );
    // Se filtran esquemas genericos
    const signatureSchemesGeneric = signaturesSchemesForSignatureType.filter((scheme) => scheme.generic);

    // Se filtran esquemas de firmas que contengan grupo funcionales y productos para la transaccion
    const signatureSchemesWithProductAndSignatureFeature = signaturesSchemesForSignatureType.filter(
        (scheme) =>
            scheme.products.length &&
            scheme.signatureFeatures.length &&
            idProduct &&
            sf &&
            scheme.products.includes(idProduct) &&
            scheme.signatureFeatures.includes(sf),
    );

    // Se filtran esquemas de firmas que contengan solo grupos funcionales para la transaccion
    const signatureSchemesWithSignatureFeature = signaturesSchemesForSignatureType.filter(
        (scheme) =>
            scheme.signatureFeatures.length &&
            sf &&
            scheme.signatureFeatures.includes(sf) &&
            userSignatureLevel &&
            scheme.signatureGroup.includes(userSignatureLevel) &&
            !scheme.products.length,
    );

    // Se filtran los esquemas de firmas por el producto de esta transaccion (REVISAR: se asume un unico producto)
    const signaturesSchemesForProduct = signaturesSchemesForSignatureType.filter(
        (scheme) =>
            scheme.products.length &&
            idProduct &&
            scheme.products.includes(idProduct) &&
            !scheme.signatureFeatures.length,
    );

    // De los esquemas se elije el o los esquemas mas restricitivos

    if (signatureSchemesWithProductAndSignatureFeature.length !== 0) {
        return signatureSchemesWithProductAndSignatureFeature;
    }
    if (signatureSchemesWithSignatureFeature.length !== 0 || signaturesSchemesForProduct.length !== 0) {
        return signatureSchemesWithSignatureFeature.concat(signaturesSchemesForProduct);
    }
    return signatureSchemesGeneric;
};

export const hasPermissionToCancel = (transaction, activeEnvironment) => {
    const requiredSignatures = getRequiredSignatures(transaction, activeEnvironment);
    const { userSignatureLevel } = activeEnvironment;

    return requiredSignatures.some((scheme) => scheme.signatureGroup.includes(userSignatureLevel));
};

export const getTotalAmount = (idActivity, transaction) => {
    if(idActivity === "echeqs.emitEcheq.send" && transaction?.data?.emissionType?.[0] === "multiple"){
        return transaction?.totalAmount;
    } else if (idActivity === "pays.supplier.check.echeq.send" || idActivity === "pays.supplier.transfer.send" || idActivity === "pays.supplier.withholdings.send" ){
        return transaction?.data.totalAmount.quantity;
    }    
};

export const getCurrency = (idActivity, transaction, transactionAmounts) => {
    let [currency] = Object.keys(transactionAmounts)
    if(idActivity === "pays.supplier.check.echeq.send" || idActivity === "pays.supplier.transfer.send" || idActivity === "pays.supplier.withholdings.send"){
        currency = transaction?.data.totalAmount.currency;
    }
    return currency;
};


