export const types = {
    SHOW_NOTIFICATION: "notification/SHOW_NOTIFICATION",
    SHOW_NOTIFICATION_REDIRECT: "notification/SHOW_NOTIFICATION_REDIRECT",
    REMOVE_NOTIFICATION: "notification/REMOVE_NOTIFICATION",
};

export const INITIAL_STATE = {
    message: null,
    level: null,
    scopes: [],
    isNotification: "",
    redirectPath: null,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.SHOW_NOTIFICATION:
            return {
                ...state,
                message: action.message,
                level: action.level,
                scopes: action.scopes,
                isNotification: action.isNotification,
            };
        case types.SHOW_NOTIFICATION_REDIRECT:
            return {
                ...state,
                message: action.message,
                level: action.level,
                scopes: action.scopes,
                redirectPath: action.redirectPath,
            };
        case types.REMOVE_NOTIFICATION:
            return INITIAL_STATE;
        default:
            return state;
    }
};

// Action creators
export const actions = {
    showNotification: (message, level, scopes, isNotification) => ({
        type: types.SHOW_NOTIFICATION,
        message,
        level,
        scopes,
        isNotification,
    }),
    showNotificationAction: (message, level, scopes, redirectPath) => ({
        type: types.SHOW_NOTIFICATION_REDIRECT,
        message,
        level,
        scopes,
        redirectPath,
    }),
    removeNotification: () => ({
        type: types.REMOVE_NOTIFICATION,
    }),
};

// Selectors
export const selectors = {
    getMessage: (state) => state.notification.message,
    getLevel: (state) => state.notification.level,
    getScopes: (state) => state.notification.scopes,
    getIsNotification: (state) => state.notification.isNotification,
    getRedirectPath: (state) => state.notification.redirectPath,
};
