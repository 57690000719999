import React, { useEffect, useState } from "react";
import { actions as eCheqsActions, selectors as echeqsSelector } from "reducers/echeqs";
import { actions as formActions, selectors as formSelector } from "reducers/form";
import { compose } from "redux";
import { func, shape, bool, string } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { goBack } from "react-router-redux";
import { Field } from "formik";
import FormTransition from "../../_components/FormTransition";
import I18n from "../../../_components/I18n";
import FormattedAmount from "../../../_components/FormattedAmount";
import FormattedDate from "../../../_components/FormattedDate";
import typeSelector from "../../../../reducers/types/form";
import * as FormFieldsComponents from "../../_components/_fields/Index";
import * as i18nUtils from "../../../../util/i18n";
import * as config from "../../../../util/config";
import Alert from "../../../_components/Alert/Alert";
import CustomerEcheqData from "./_components/CustomerEcheqData";

const ID_ACTIVITY = "echeqs.endorseRequest.send";
const ID_ACTIVITY_PRE = "echeqs.endorseRequest.pre";
const ID_FORM = `forms.${ID_ACTIVITY}`.replace(".send", "");
const EndorsementRequest = (props) => {
    const {
        mode,
        dispatch,
        currentLang,
        fromBackoffice,
        transaction,
        data,
        isMobile,
        echeq,
        location,
        bankedCustomer,
        errors,
        history,
    } = props;

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    const idTransaction = transaction?.idTransaction;

    const preData = data?.preData || props?.preData;
    const imfOptions = preData?.imf.map((item) => ({
        id: item?.id,
        label: item?.label,
    }));

    const efOptions = preData?.entities.map((item) => ({
        id: item?.id,
        label: item?.label,
    }));

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const endorsementTypes = config.getArray("echeq.endorsementType").map((item) => ({
        id: item,
        label: i18nUtils.get(`${ID_FORM}.endorsementType.${item}.label`),
    }));

    const echeqSelected = Object.keys(echeq).length ? echeq : data?.echeq;
    const [endorsementType, setEndorsementType] = useState(data?.endorsementType ?? ["NOM"]);
    const [guarantorInfo, setGuarantorInfo] = useState(data?.guarantor ?? bankedCustomer);
    const [guarantorData, setGuarantorData] = useState(
        data?.guarantor ?? {
            documentNumber: "",
            documentType: "",
        },
    );

    const handleClickDownload = () => dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    const handleBack = () => history.goBack();

    useEffect(() => {
        if (Object.keys(bankedCustomer).length) {
            setGuarantorInfo(bankedCustomer);
            setGuarantorData({
                documentNumber: bankedCustomer.documento,
                documentType: bankedCustomer.documento_tipo,
                name: bankedCustomer.nombre,
            });
        }
    }, [bankedCustomer]);

    useEffect(() => {
        if (mode === "edit") {
            if (echeqSelected && Object.keys(echeqSelected).length > 0) {
                dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData: {} }));
            } else {
                dispatch(goBack());
            }
        } else {
            dispatch({
                type: typeSelector.PRE_FORM_SUCCESS,
            });
        }
    }, [dispatch, location, transaction]);

    const renderFields = (setFieldValue) => {
        function searchSuccessAction(bankedCustomerInfoData) {
            setFieldValue("bankedCustomerInfo", bankedCustomerInfoData);
        }

        function getDataFromComponent(searchInfo) {
            setGuarantorData({
                documentNumber: searchInfo.documentNumber,
                documentType: searchInfo.documentType,
            });
        }

        return (
            <>
                {mode === "edit" && (
                    <div className="form-text-info">
                        <I18n id={`${ID_FORM}.message.text`} />
                    </div>
                )}

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.echeqInfo.title`} componentProps={{ className: "data-label ui-fw-bold" }} />
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.checkId.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.cheque_id}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.checkNumber.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.cheque_numero}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.type.label`} componentProps={{ className: "data-label" }} />
                    <I18n id={`forms.echeqs.type.${echeqSelected?.cheque_tipo}`} />
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.character.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.cheque_caracter}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.issuerDocument.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.emisor_cuit}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.issuerName.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.emisor_razon_social}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.beneficiary.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.beneficiario_nombre}</span>
                </div>

                {mode === "edit" && (
                    <div className="data-wrapper">
                        <I18n id={`${ID_FORM}.status.label`} componentProps={{ className: "data-label" }} />
                        <span>{echeqSelected?.estado}</span>
                    </div>
                )}

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.amount.label`} componentProps={{ className: "data-label" }} />
                    <FormattedAmount currency={echeqSelected?.emisor_moneda} quantity={echeqSelected?.monto} />
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.paymentDate.label`} componentProps={{ className: "data-label" }} />
                    <FormattedDate date={echeqSelected?.fecha_pago} dateFormat="DD/MM/YYYY" />
                </div>

                {mode === "edit" ? (
                    <>
                        <hr className={`ui-mt-7 ${mode === "edit" ? "ui-mb-2" : "ui-mb-7"}`} />
                        <div className="data-wrapper">
                            <I18n
                                id={`${ID_FORM}.section.endorsement.title`}
                                componentProps={{ className: "data-label ui-fw-bold" }}
                            />
                        </div>

                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={endorsementTypes}
                            key="endorsementType"
                            name="endorsementType"
                            renderAs="combo"
                            idField="endorsementType"
                            /* value={data?.endorsementType ? data.endorsementType[0] : [endorsementType]} */
                            value={data?.endorsementType ? data.endorsementType[0] : endorsementType}
                            onChange={(e) => {
                                setEndorsementType([e?.id]);
                                setGuarantorInfo({});
                                setGuarantorData({
                                    documentNumber: "",
                                    documentType: "",
                                });
                                dispatch(eCheqsActions.cleanBankedCustomerInfo());
                            }}
                        />
                    </>
                ) : (
                    <>
                        <hr className="ui-mt-7 ui-mb-7" />
                        <I18n
                            id={`${ID_FORM}.section.endorsement.title`}
                            componentProps={{ className: "data-label ui-fw-bold" }}
                        />
                        <div className="data-wrapper">
                            <I18n
                                id={`${ID_FORM}.endorsementType.label`}
                                componentProps={{ className: "data-label" }}
                            />
                            <span>{i18nUtils.get(`${ID_FORM}.endorsementType.${endorsementType[0]}.label`)}</span>
                        </div>
                    </>
                )}

                {endorsementType.includes("NOM") ? (
                    <>
                        {mode === "edit" && !Object.keys(guarantorInfo).length ? (
                            <>
                                <I18n
                                    id={`${ID_FORM}.customerBanked.section.title`}
                                    componentProps={{ className: "data-label" }}
                                />
                                {echeqSelected?.cheque_caracter.toLowerCase() === "a la orden" ? (
                                    <>
                                        <CustomerEcheqData
                                            idActivity={ID_ACTIVITY}
                                            genericProps={genericProps}
                                            isMobile={isMobile}
                                            searchSuccessAction={searchSuccessAction}
                                            data={data}
                                            buttonLabel={`${ID_FORM}.consult.label`}
                                            sendDataBack={getDataFromComponent}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Alert
                                            icon="images/ui-icons/ui-exclamation-orange.svg"
                                            className="ui-mt-3 ui-mb-7">
                                            <I18n id={`${ID_FORM}.endorsementType.NOM.warning.text`} />
                                        </Alert>
                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.Selector}
                                            optionList={efOptions}
                                            key="cuit"
                                            name="cuit"
                                            renderAs="combo"
                                            idField="cuit"
                                            onChange={(e) => {
                                                const efData = preData?.entities.find((item) => item.id === e.id);
                                                setGuarantorInfo({
                                                    documento: efData?.id,
                                                    documento_tipo: efData?.documentType,
                                                    nombre: efData?.label,
                                                });
                                                setGuarantorData({
                                                    documentNumber: efData?.id,
                                                    documentType: efData?.documentType,
                                                    name: efData?.label,
                                                });
                                            }}
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="data-wrapper">
                                    <I18n
                                        id={`${ID_FORM}.customerBanked.section.title`}
                                        componentProps={{ className: "data-label" }}
                                    />
                                    <span>
                                        {guarantorInfo?.nombre || guarantorInfo?.aval_nombre || guarantorInfo?.name}
                                    </span>
                                </div>

                                <div className="data-wrapper">
                                    <I18n
                                        id={`${ID_FORM}.document.label`}
                                        componentProps={{ className: "data-label" }}
                                    />
                                    <span>{`${guarantorInfo?.documento_tipo ||
                                        guarantorInfo?.aval_documento_tipo ||
                                        guarantorInfo?.documentType} ${guarantorInfo?.documento ||
                                        guarantorInfo?.aval_documento ||
                                        guarantorInfo?.documentNumber}`}</span>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {mode === "edit" && !Object.keys(guarantorInfo).length ? (
                            <>
                                <Alert icon="images/ui-icons/ui-exclamation-orange.svg" className="ui-mb-7">
                                    <I18n id={`${ID_FORM}.endorsementType.NEG.warning.text`} />
                                </Alert>
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Selector}
                                    optionList={imfOptions}
                                    key="cuit"
                                    name="cuit"
                                    renderAs="combo"
                                    idField="cuit"
                                    onChange={(e) => {
                                        const imfData = preData?.imf.find((item) => item.id === e.id);
                                        setGuarantorInfo({
                                            documento: imfData?.id,
                                            documento_tipo: imfData?.documentType,
                                            nombre: imfData?.label,
                                        });
                                        setGuarantorData({
                                            documentNumber: imfData?.id,
                                            documentType: imfData?.documentType,
                                            name: imfData?.label,
                                        });
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <div className="data-wrapper">
                                    <I18n
                                        id={`${ID_FORM}.customerBanked.section.title`}
                                        componentProps={{ className: "data-label" }}
                                    />
                                    <span>
                                        {guarantorInfo?.nombre || guarantorInfo?.aval_nombre || guarantorInfo?.name}
                                    </span>
                                </div>

                                <div className="data-wrapper">
                                    <I18n
                                        id={`${ID_FORM}.document.label`}
                                        componentProps={{ className: "data-label" }}
                                    />
                                    <span>{`${guarantorInfo?.documento_tipo ||
                                        guarantorInfo?.aval_documento_tipo ||
                                        guarantorInfo?.documentType} ${guarantorInfo?.documento ||
                                        guarantorInfo?.aval_documento ||
                                        guarantorInfo?.documentNumber}`}</span>
                                </div>
                            </>
                        )}
                    </>
                )}
            </>
        );
    };

    const formProps = {
        title: `${ID_FORM}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            echeq: echeqSelected,
            checkId: echeqSelected?.cheque_id,
            endorsementType,
            guarantor: guarantorData,
            amount: data?.amount || {
                quantity: echeqSelected?.monto,
                currency: echeqSelected?.emisor_moneda,
            },
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
        handleBack,
        returnBack: true,
        errorsAux: errors,
    };

    return <FormTransition {...props} {...formProps} />;
};

EndorsementRequest.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
    isMobile: false,
};

EndorsementRequest.propTypes = {
    dispatch: func.isRequired,
    history: func.isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    echeq: shape({}).isRequired,
    mode: string.isRequired,
    location: shape({}).isRequired,
    bankedCustomer: shape({}).isRequired,
    isMobile: bool,
    fetching: bool.isRequired,
    useDefaultSubmit: bool,
    errors: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    echeq: echeqsSelector.getSelectedEcheq(state),
    transaction: formSelector.getTransaction(state),
    data: formSelector.getData(state),
    preData: formSelector.getPreData(state),
    mode: formSelector.getMode(state),
    bankedCustomer: echeqsSelector.getBankedCustomerInfo(state),
    fetching: formSelector.getFetching(state),
    errors: echeqsSelector.getErrors(state),
});

export default compose(connect(mapStateToProps), withRouter)(EndorsementRequest);
