import React, { Component } from "react";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import { string, shape, bool, number, func } from "prop-types";
import I18n from "pages/_components/I18n";
import GenericItem from "components/GenericItem/GenericItem";
import Table from "pages/_components/Table";
import Image from "pages/_components/Image";
import classNames from "classnames";
import EcheqActions from "./EcheqActions";
import EcheqSubStates from "./EcheqSubStates";

class ECheq extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        echeq: shape({
            date: string,
            echeqNumber: string,
            state: string,
            account: shape({
                number: string,
                currency: string,
                formattedAccountNumber: string,
            }),
            amount: number,
            debitDate: string,
            currency: string,
            productType: string,
            accreditationDate: string,
            rejectionReason: string,
        }).isRequired,
        echeqType: string.isRequired,
        idActivity: string.isRequired,
        onClick: func.isRequired,
        dispatch: func.isRequired,
        activeEnvironment: shape({}).isRequired,
        hasPendingTransactions: bool.isRequired,
        customArrowFunction: func,
    };

    static defaultProps = {
        customArrowFunction: null,
    };

    // realizar mapeo similar para "RECIBIDO DE" en recibidos
    getSentData = (echeq) => {
        const {
            avalistas,
            endosos,
            cesiones,
            emisor_cuit,
            fecha_emision,
            beneficiario_nombre,
            beneficiario_documento_tipo,
            beneficiario_documento,
        } = echeq;
        // tomamos el mas reciente de cada uno (ya llegan ordenados)
        // agregar mas adelante el resto: MANDATOS, RECHAZOS, DEVOLUCIONES, etc de aplicar
        const { activeEnvironment } = this.props;
        const newestDatesArray = [];
        const clientDocNumber = activeEnvironment?.clients ? activeEnvironment.clients[0].idClient : null;
        const clientGuarantorLastData =
            avalistas?.filter((avalista) => avalista.aval_documento === clientDocNumber)[0] || null;
        const clientEndorsementLastData =
            endosos?.filter((endoso) => endoso.emisor_documento === clientDocNumber)[0] || null;
        const clientCessionLastData =
            cesiones?.filter((cesion) => cesion.cedente_documento === clientDocNumber)[0] || null;
        if (clientGuarantorLastData !== null) {
            newestDatesArray.push({
                dateSent: clientGuarantorLastData.aval_fecha,
                // aval no trae numero ni tipo de doc, tenencia viene vacia si no es tenencia del propio cliente, se deja null
                sentToDocument: null,
                sentToName: clientGuarantorLastData.aval_sujeto_avalado,
            });
        }
        if (clientEndorsementLastData !== null) {
            newestDatesArray.push({
                dateSent: clientEndorsementLastData.fecha_hora,
                sentToDocument: `${clientEndorsementLastData.benef_documento_tipo.toUpperCase()} ${
                    clientEndorsementLastData.benef_documento
                }`,
                sentToName: clientEndorsementLastData.benef_razon_social,
            });
        }
        if (clientCessionLastData !== null) {
            newestDatesArray.push({
                dateSent: clientCessionLastData.fecha_emision_cesion,
                sentToDocument: `${clientCessionLastData.cesionario_documento_tipo.toUpperCase()} ${
                    clientCessionLastData.cesionario_documento
                }`,
                sentToName: clientCessionLastData.cesionario_nombre,
            });
        }

        if (newestDatesArray.length === 0) {
            return clientDocNumber === emisor_cuit
                ? {
                      dateSent: fecha_emision,
                      sentToDocument: `${beneficiario_documento_tipo.toUpperCase()} ${beneficiario_documento}`,
                      sentToName: beneficiario_nombre,
                  }
                : {};
        }
        return newestDatesArray.reduce((currentData, nextData) =>
            Date.parse(nextData.sentDate) > Date.parse(currentData.sentDate) ? nextData : currentData,
        );
    };

    renderECheqMobile = (isDeferred, activeEnvironment, sentData, colorEstado) => {
        const {
            echeq,
            echeqType,
            idActivity,
            onClick,
            dispatch,
            isDesktop,
            hasPendingTransactions,
            customArrowFunction,
        } = this.props;
        const {
            cheque_numero,
            emisor_razon_social,
            emisor_cuit,
            cheque_caracter,
            emisor_moneda,
            monto,
            fecha_emision,
            fecha_pago,
            estado,
        } = echeq;

        return (
            <GenericItem
                arrow
                onClick={onClick}
                role="button"
                className={classNames({
                    "disabled": hasPendingTransactions,
                })}
                options={
                    <EcheqActions
                        echeq={echeq}
                        echeqType={echeqType}
                        idActivity={idActivity}
                        dispatch={dispatch}
                        activeEnvironment={activeEnvironment}
                        isMobile={!isDesktop}
                    />
                }
                customArrowFunction={customArrowFunction}>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`forms.${idActivity}.${echeqType}.number.label`} />
                    </div>
                    <div className="data-row deferred-icon">
                        <span>{cheque_numero}</span>
                        {isDeferred && (
                            <Image src="images/ui-icons/ui-echeq-d.svg" className="svg-icon" alt="Diferido" />
                        )}
                    </div>
                </div>

                {echeqType === "received" ? (
                    <>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id={`forms.${idActivity}.${echeqType}.receivedFrom.label`} />
                            </div>
                            <div>
                                <span className="data-text">{emisor_razon_social}</span>
                            </div>
                            <div>
                                <span className="data-text">{emisor_cuit}</span>
                            </div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id={`forms.${idActivity}.${echeqType}.character.label`} />
                            </div>
                            <span className="data-text">{cheque_caracter}</span>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id={`forms.${idActivity}.${echeqType}.issueDate.label`} />
                            </div>
                            <span>
                                {/* depende el ESTADO, mostrar distintas fechas? */}
                                <FormattedDate date={fecha_emision} />
                            </span>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id={`forms.${idActivity}.${echeqType}.sentTo.label`} />
                            </div>
                            {/* depende el ESTADO, sacar beneficiario de distinto lugar? */}
                            <div>
                                <span className="data-text">{sentData?.sentToName || null}</span>
                            </div>
                            <div>
                                <span className="data-text">{sentData?.sentToDocument || null}</span>
                            </div>
                        </div>
                    </>
                )}
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`forms.${idActivity}.${echeqType}.amount.label`} />
                    </div>

                    <FormattedAmount currency={emisor_moneda || "ARS"} quantity={monto} />
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`forms.${idActivity}.${echeqType}.paymentDate.label`} />
                    </div>
                    <span>
                        <FormattedDate date={fecha_pago} />
                    </span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`forms.${idActivity}.${echeqType}.status.label`} />
                    </div>
                    <I18n
                        id={`forms.echeqs.list.status.${estado}.label`}
                        componentProps={{
                            className: `data-text ui-text-uppercase ${!hasPendingTransactions && colorEstado}`,
                        }}
                    />
                </div>
                <div className="data-wrapper">
                    <div className="data-label" />
                    <EcheqSubStates echeq={echeq} isDesktop={isDesktop} />
                </div>
            </GenericItem>
        );
    };

    render() {
        const { isDesktop, echeq, echeqType, idActivity, dispatch, activeEnvironment } = this.props;
        const {
            cheque_numero,
            emisor_razon_social,
            emisor_cuit,
            cheque_caracter,
            emisor_moneda,
            monto,
            fecha_pago,
            fecha_emision,
            estado,
            relatedPendingTransactions,
        } = echeq;
        const paymentDate = fecha_pago.substring(0, 10);
        const emissionDate = fecha_emision.substring(0, 10);
        const isDeferred = emissionDate !== paymentDate;
        const sentData = this.getSentData(echeq);
        let colorEstado = "";
        if (["CADUCADO", "RECHAZADO", "REPUDIADO", "ANULADO"].includes(estado)) {
            colorEstado = "ui-text-error";
        } else if (estado === "ACTIVO") {
            colorEstado = "ui-text-success";
        }

        const hasPendingTransactions = relatedPendingTransactions?.length > 0;

        return isDesktop ? (
            <>
                <Table.Data align="left">
                    <span>{cheque_numero}</span>
                </Table.Data>

                <Table.Data className="deferred-icon">
                    {isDeferred && <Image src="images/ui-icons/ui-echeq-d.svg" className="svg-icon" alt="Diferido" />}
                </Table.Data>

                {echeqType === "received" ? (
                    <>
                        <Table.Data align="left">
                            <div>
                                <span className="data-text">{emisor_razon_social}</span>
                            </div>
                            <div>
                                <span className="data-text">{emisor_cuit}</span>
                            </div>
                        </Table.Data>

                        <Table.Data align="left">
                            <span>{cheque_caracter}</span>
                        </Table.Data>
                    </>
                ) : (
                    <>
                        <Table.Data align="left">
                            <FormattedDate date={fecha_emision} />
                        </Table.Data>

                        <Table.Data align="left">
                            <div>
                                <span className="data-text">{sentData?.sentToName || null}</span>
                            </div>
                            <div>
                                <span className="data-text">{sentData?.sentToDocument || null}</span>
                            </div>
                        </Table.Data>
                    </>
                )}

                <Table.Data align="right">
                    <FormattedAmount currency={emisor_moneda || "ARS"} quantity={monto} />
                </Table.Data>

                <Table.Data align="left">
                    <FormattedDate date={fecha_pago} />
                </Table.Data>

                <Table.Data align="left">
                    <I18n
                        id={`forms.echeqs.list.status.${estado}.label`}
                        componentProps={{ className: `ui-text-uppercase ${!hasPendingTransactions && colorEstado}` }}
                    />
                </Table.Data>

                <Table.Data align="left" styles={{ maxWidth: "165px" }}>
                    <EcheqSubStates echeq={echeq} isDesktop={isDesktop} />
                </Table.Data>

                <Table.Data className="table-data-icon dropdown" align="right">
                    <EcheqActions
                        echeq={echeq}
                        echeqType={echeqType}
                        idActivity={idActivity}
                        dispatch={dispatch}
                        activeEnvironment={activeEnvironment}
                        isMobile={!isDesktop}
                    />
                </Table.Data>

                <Table.Data className="table-data-icon chevrom" align="right">
                    <ChevromRight />
                </Table.Data>
            </>
        ) : (
            this.renderECheqMobile(isDeferred, activeEnvironment, sentData, colorEstado)
        );
    }
}

export default ECheq;
