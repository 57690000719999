import React from "react";
import Nav from "react-bootstrap/lib/Nav";
import { connect } from "react-redux";
import { func, string, shape, bool, number, node } from "prop-types";

import { actions as checksActions } from "reducers/checks";
import { actions as miscActions } from "reducers/misc";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { selectors as accountsSelectors } from "reducers/accounts";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as transactionsSelectors } from "reducers/transactions";
import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import * as i18n from "util/i18n";
import * as config from "util/config";

import MenuFormItem from "pages/_components/menu/MenuFormItem";
import MenuItem from "pages/_components/menu/MenuItem";
import MenuSection from "pages/_components/menu/MenuSection";
import ToggleSidebar from "./ToggleSidebar";
import TooltipUi from "../TooltipUi/TooltipUi";

class Menu extends React.Component {
    static propTypes = {
        location: shape({
            pathname: string,
        }),
        dispatch: func.isRequired,
        isMobile: bool,
        isAdministrator: bool,
        environments: shape({}),
        activeEnvironment: shape({
            permissions: shape({
                accounts: bool,
                creditcards: bool,
                payCreditCard: bool,
                payLoan: bool,
                payThirdPartiesCreditCard: bool,
                payThirdPartiesLoan: bool,
                requestTransactionCancellation: bool,
                transferForeign: bool,
                transferInternal: bool,
                transferLocal: bool,
                transferThirdParties: bool,
            }),
            forms: shape(),
            administrationScheme: string,
            type: string,
        }).isRequired,
        lang: string.isRequired,
        isCollapsed: bool,
        onToggleSidebar: func,
        pendingTransactionsQuantity: number,
        setNavbarHover: func,
        isSidebarHovered: bool,
        availableFeatures: shape({}).isRequired,
        hasAccountQr: bool.isRequired,
    };

    static defaultProps = {
        isMobile: false,
        isAdministrator: false,
        isCollapsed: false,
        pendingTransactionsQuantity: 0,
        isSidebarHovered: false,
        environments: {},
        onToggleSidebar: null,
        setNavbarHover: null,
        location: null,
    };

    state = {
        tooltipDesktop: {
            showTooltip: false,
            tooltipPosition: "right",
            tooltipTarget: null,
        },
    };

    showTooltip = (target) => {
        this.setState((prevState) => ({
            tooltipDesktop: {
                showTooltip: true,
                tooltipPosition: prevState.tooltipPosition,
                tooltipTarget: target,
            },
        }));
    };

    hideTooltip = () => {
        this.setState((prevState) => ({
            tooltipDesktop: {
                showTooltip: false,
                tooltipPosition: prevState.tooltipPosition,
                tooltipTarget: null,
            },
        }));
    };

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.logout());
    };

    renderModalLogout = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.setModalLogout(true));
    };

    renderModalMiPyMEConsultation = () => {
        const { dispatch } = this.props;
        dispatch(miscActions.setModalInvoiceMipymeConsultation(true));
    };

    goCertificateCalfCemUrl = () => {
        const { dispatch } = this.props;
        dispatch(miscActions.getCertificateCalfCem());
    };

    setAccountNull = () => {
        const { dispatch } = this.props;
        dispatch(checksActions.setAccountIdSelected(null));
    };

    goJump = () => {
        const { dispatch } = this.props;
        dispatch(miscActions.getJumpUrl());
    };

    goGire = () => {
        const { dispatch } = this.props;
        dispatch(miscActions.getEcheqUrl());
    };

    goHelp = () => {
        window.location.href = config.get("client.helpURL", "#");
    };

    goCedip = () => {
        const { dispatch } = this.props;
        dispatch(miscActions.getCedipUrl());
    };

    buildAdministrationMenu = (location) => {
        const { isMobile, isAdministrator, activeEnvironment, isSidebarHovered } = this.props;

        let admMenu = null;
        if (!isMobile && isAdministrator) {
            if (activeEnvironment.administrationScheme !== "simple") {
                admMenu = (
                    <MenuSection
                        titleKey="administration.menu.name"
                        key="menu.administration"
                        image="images/ui-icons/menu/administration.svg"
                        isSidebarHovered={isSidebarHovered}>
                        <MenuItem
                            location={location}
                            titleKey="administration.menu.users"
                            key="menu.administration.users"
                            linkTo="/administration/users"
                            isMobile={isMobile}
                        />
                        {activeEnvironment.administrationScheme !== "medium" && (
                            <MenuItem
                                location={location}
                                titleKey="administration.menu.groups"
                                key="menu.administration.groups"
                                linkTo="/administration/groups"
                                isMobile={isMobile}
                            />
                        )}
                        <MenuItem
                            location={location}
                            titleKey="administration.menu.signatureScheme"
                            key="menu.administration.signatureScheme"
                            linkTo={`/administration/${activeEnvironment.administrationScheme}/signaturesSchemes`}
                            isMobile={isMobile}
                        />
                        <MenuItem
                            location={location}
                            titleKey="administration.restrictions.environment.title"
                            key="menu.administration"
                            linkTo="/administration/restrictions"
                            isMobile={isMobile}
                        />
                    </MenuSection>
                );
            } else {
                admMenu = (
                    <MenuItem
                        location={location}
                        titleKey="administration.menu.name"
                        key="menu.administration"
                        linkTo="/administration/users"
                        image="images/ui-icons/menu/administration.svg"
                        isMobile={isMobile}
                    />
                );
            }
        }

        return admMenu;
    };

    constructMenuFormItems(sectionForms) {
        const { lang, isMobile } = this.props;
        const menuItems = [];

        if (sectionForms && sectionForms.length > 0) {
            for (let i = 0; i < sectionForms.length; i++) {
                menuItems.push(
                    <MenuFormItem
                        link={`/form/${sectionForms[i].idForm}`}
                        name={sectionForms[i].name[lang]}
                        key={sectionForms[i].idForm}
                        isMobile={isMobile}
                    />,
                );
            }
        }
        return menuItems;
    }

    constructMenuSection(activeEnvironment, menuName, ImageSection, location) {
        const { isMobile, isSidebarHovered, availableFeatures, hasAccountQr } = this.props;
        let menuSection = null;
        let menuItems = [];

        const sectionForms = activeEnvironment && activeEnvironment.forms[menuName];

        menuItems = menuItems.concat(this.constructMenuFormItems(sectionForms));

        if (activeEnvironment && menuName === "payments") {
            const showMenuItem = config.get("menu.payments.supplierPayment.showMenuItem", false) === "true";

            if (showMenuItem) {
                menuItems.push(
                    <MenuItem
                        titleKey="menu.payments.suppliers.fileList"
                        key="menu.payments.suppliers.fileList"
                        linkTo="/paymentSuppliers/files"
                        featureEnabled={!!availableFeatures?.["payments.suppliers"]}
                        hasPermission={
                            !!activeEnvironment.permissions["suppliers.checkEcheq"] ||
                            !!activeEnvironment.permissions["suppliers.transfer"] ||
                            !!activeEnvironment.permissions["suppliers.withholdings"]
                        }
                        isMobile={isMobile}
                    />,
                );
            }
        }
        if (activeEnvironment && menuName === "accounts") {
            menuItems.push(
                <MenuItem
                    location={location}
                    titleKey="menu.accounts.myAccounts"
                    key="menu.accounts.myAccounts"
                    linkTo="/accounts"
                    hasPermission={!activeEnvironment.permissions.environmentHasNoAccounts}
                    featureEnabled={!!availableFeatures?.["accounts"]}
                    isMobile={isMobile}
                />,

                <MenuItem
                    location={location}
                    titleKey="menu.checks.listChecks"
                    key="menu.checks.listChecks"
                    linkTo="/checks"
                    featureEnabled={!!availableFeatures?.["checks"]}
                    isMobile={isMobile}
                />,

                <MenuFormItem
                    location={location}
                    key="menu.accounts.requestCheckbook"
                    name={i18n.get("menu.accounts.requestCheckbook")}
                    link="/formCustom/requestCheckbook"
                    onClick={() => {
                        this.setAccountNull();
                    }}
                    hasPermission={!!activeEnvironment.permissions["accounts.requestCheckbook"]}
                    featureEnabled={!!availableFeatures?.["checks.request"]}
                    isMobile={isMobile}
                />,

                <MenuFormItem
                    location={location}
                    key="accountOpening"
                    link="/formCustom/accountOpening"
                    name={i18n.get("menu.account.requestOpening")}
                    hasPermission={!!activeEnvironment.permissions.accountOpening}
                    featureEnabled={!!availableFeatures?.["accounts.requestOpening"]}
                    isMobile={isMobile}
                />,

                <MenuFormItem
                    location={location}
                    key="accountClosing"
                    link="/formCustom/accountClosing"
                    name={i18n.get("menu.account.requestClosing")}
                    hasPermission={
                        !activeEnvironment.permissions.environmentHasNoAccounts &&
                        !!activeEnvironment.permissions.accountClosing
                    }
                    featureEnabled={!!availableFeatures?.["accounts.requestClosing"]}
                    isMobile={isMobile}
                />,

                <MenuItem
                    location={location}
                    titleKey="menu.accounts.statements"
                    key="menu.accounts.statements"
                    linkTo="/accounts/statements/list"
                    featureEnabled={!!availableFeatures?.["accounts"]}
                    isMobile={isMobile}
                />,
                <MenuItem
                    location={location}
                    titleKey="menu.accounts.qrChargesCertificates"
                    key="menu.accounts.qrChargesCertificates"
                    linkTo="/qrChargesCertificates/initValue"
                    isMobile={isMobile}
                    featureEnabled={hasAccountQr}
                    disableMessage={i18n.get("menu.disable.message.qr")}
                />,
            );
        }

        if (activeEnvironment && menuName === "echeqs") {
            const useGireForEcheqs = config.get("echeq.useGireForEcheqs", false) === "true";
            const menuProps = useGireForEcheqs ? { target: "_blank" } : {};
            const { permissions } = activeEnvironment;
            const featuresEcheqEnabled =
                !!availableFeatures?.["echeqs"] &&
                (permissions["echeqs.termsAndConditions.alreadySigned"] ||
                    permissions["echeqs.termsAndConditions.adherence"]);

            if (useGireForEcheqs) {
                menuItems.push(
                    <MenuItem
                        location={location}
                        titleKey="menu.echeqs"
                        key="menu.echeqs"
                        onClick={this.goGire}
                        isMobile={isMobile}
                        hasPermission={useGireForEcheqs}
                        featureEnabled={useGireForEcheqs}
                        {...menuProps}
                    />,
                );
            } else {
                menuItems.push(
                    <MenuItem
                        location={location}
                        titleKey="menu.echeqs"
                        key="menu.echeqs"
                        linkTo="/echeqs"
                        isMobile={isMobile}
                        hasPermission={!!permissions.listEcheqs}
                        featureEnabled={featuresEcheqEnabled}
                        {...menuProps}
                    />,
                    <MenuItem
                        location={location}
                        titleKey="menu.echeqs.management"
                        key="menu.echeqs.management"
                        linkTo="/echeqs/management/account/list"
                        isMobile={isMobile}
                        hasPermission={!!permissions["echeqs.accountAdherence"]}
                        featureEnabled={featuresEcheqEnabled}
                    />,
                );
            }
        }

        if (activeEnvironment && menuName === "transfers") {
            menuItems.push(
                <MenuFormItem
                    link="/formCustom/transferInternal"
                    key="transferInternal"
                    name={`${i18n.get("menu.transfers.internal")}`}
                    hasPermission={!!activeEnvironment.permissions.transferInternal}
                    hasTransferInternalAccounts={!!activeEnvironment.permissions.hasTransferInternalAccounts}
                    featureEnabled={!!availableFeatures?.["transferInternal"]}
                    isMobile={isMobile}
                />,

                <MenuFormItem
                    link="/formCustom/transferThirdParties"
                    key="transferThirdParties"
                    name={i18n.get("menu.transfers.thirdParties")}
                    hasPermission={!!activeEnvironment.permissions.transferThirdParties}
                    featureEnabled={!!availableFeatures?.["transferThirdParties"]}
                    onClick={() => {
                        this.setAccountNull();
                    }}
                    isMobile={isMobile}
                />,

                <MenuFormItem
                    link="/formCustom/transferLocal"
                    key="transferLocal"
                    name={i18n.get("menu.transfers.local")}
                    hasPermission={!!activeEnvironment.permissions.transferLocal}
                    featureEnabled={!!availableFeatures?.["transferLocal"]}
                    onClick={() => {
                        this.setAccountNull();
                    }}
                    isMobile={isMobile}
                />,
            );

            if (!isMobile) {
                menuItems.push(
                    <MenuFormItem
                        link="/formCustom/transferMassive"
                        key="transferMassive"
                        name={`${i18n.get("menu.transfers.massive")}`}
                        hasPermission={!!activeEnvironment.permissions.transfersMassive}
                        featureEnabled={!!availableFeatures?.["payments"]}
                        isMobile={isMobile}
                    />,
                );
            }

            menuItems.push(
                <MenuFormItem
                    link="/recipients"
                    key="frequentRecipients"
                    name={`${i18n.get("menu.transfers.frequentRecipients")}`}
                    hasPermission={!!activeEnvironment.permissions.frequentDestinations}
                    featureEnabled={!!availableFeatures?.["frequentDestinations"]}
                    isMobile={isMobile}
                />,
            );
        }

        if (menuName === "loans" && activeEnvironment) {
            menuItems.push(
                <MenuItem
                    location={location}
                    titleKey="menu.loans.list.label"
                    key="menu.loans.list.label"
                    linkTo="/loansList"
                    isMobile={isMobile}
                    hasPermission={
                        !!activeEnvironment.permissions["loans.operations"] &&
                        (!!activeEnvironment.permissions["loans.active"] ||
                            !!activeEnvironment.permissions["loans.history"])
                    }
                    featureEnabled={!!availableFeatures?.["loans"]}
                />,

                <MenuItem
                    location={location}
                    titleKey="menu.check.deferred"
                    key="menu.check.deferred"
                    linkTo="/ChecksDeferredList"
                    featureEnabled={!!availableFeatures?.["checks"]}
                    isMobile={isMobile}
                />,
            );
        }
        if (menuName === "investments" && activeEnvironment) {
            const showCedipJump = config.get("investment.cedip.jump.available", false) === "true";
            menuItems.push(
                <MenuItem
                    titleKey="menu.investments.listFixedTermDeposits"
                    key="menu.investments.listFixedTermDeposits"
                    linkTo="/fixedTermDeposits"
                    featureEnabled={!!availableFeatures?.["fixedTermDeposit.list"]}
                    isMobile={isMobile}
                />,

                <MenuItem
                    titleKey="menu.investments.fixedTermDeposit.new"
                    key="menu.investments.fixedTermDeposit.new"
                    linkTo="/formCustom/createFixedTermDeposit"
                    hasPermission={!!activeEnvironment.permissions.fixedTermDepositOpening}
                    featureEnabled={!!availableFeatures?.["fixedTermDeposit.create"]}
                    isMobile={isMobile}
                />,

                <MenuItem
                    titleKey="menu.investments.mutualFund"
                    key="menu.investments.mutualFund"
                    linkTo="/mutualFunds"
                    hasPermission={!!activeEnvironment.permissions.mutualFundsRead}
                    featureEnabled={!!availableFeatures?.["investments.shareholderAccountList"]}
                    isMobile={isMobile}
                />,

                <MenuItem
                    titleKey="investments.mutualFund.request.account.label"
                    key="investments.mutualFund.request.account.label"
                    linkTo="/formCustom/shareholderAccountOpening"
                    hasPermission={!!activeEnvironment.permissions.investmentShareholderOpening}
                    featureEnabled={!!availableFeatures?.["investments.shareholderAccountOpening"]}
                    isMobile={isMobile}
                />,
                <MenuItem
                    titleKey="investments.mutualFund.investerProfile"
                    key="investments.mutualFund.investerProfile"
                    linkTo="/investerProfile"
                    hasPermission={!!activeEnvironment.permissions.inversorProfiler}
                    featureEnabled={!!availableFeatures?.["investments.investorProfileForm"]}
                    isMobile={isMobile}
                />,
            );
            if(showCedipJump){
                menuItems.push(
                    <MenuItem
                        titleKey="investments.jump.cedip.label"
                        key="investments.jump.cedip.label"
                        onClick={this.goCedip}
                        hasPermission={!!activeEnvironment.permissions["cedip.jump.permission"]}
                        featureEnabled={!isMobile}  
                        disableMessage={i18n.get("menu.cedip.jump.unavailable.mobile.label")}             
                    />,       
                )
            }
        }
        if (menuName === "invoiceMiPyME" && activeEnvironment) {
            menuItems.push(
                <MenuItem
                    location={location}
                    titleKey="menu.invoiceMiPyME.consultation"
                    key="menu.invoiceMiPyME.consultation"
                    onClick={this.renderModalMiPyMEConsultation}
                    featureEnabled={!!availableFeatures?.["electronicBill.consultation"]}
                    isMobile={isMobile}
                />,
                <MenuItem
                    location={location}
                    titleKey="menu.invoiceMiPyME.cal-fcemCertificate"
                    key="menu.invoiceMiPyME.cal-fcemCertificate"
                    onClick={this.goCertificateCalfCemUrl}
                    hasPermission={!!activeEnvironment.permissions.electronicInvoice}
                    featureEnabled={!!availableFeatures?.["electronicBill.certificate"]}
                    isMobile={isMobile}
                />,
            );
        }
        if (menuName === "comex" && activeEnvironment) {
            menuItems.push(
                <MenuItem
                    titleKey="menu.comex.receiptsList"
                    key="menu.comex.receiptsList"
                    linkTo="/receiptsComexList"
                    hasPermission={!!activeEnvironment.permissions.receiptsComex}
                    featureEnabled={!!availableFeatures?.["comex"]}
                    isMobile={isMobile}
                />,
            );
        }

        if (menuItems.length > 0) {
            menuSection = (
                <MenuSection
                    id={`menu.${menuName}`}
                    titleKey={`menu.${menuName}`}
                    key={`menu.${menuName}`}
                    image={ImageSection}
                    isSidebarHovered={isSidebarHovered}
                    isMobile={isMobile}>
                    {menuItems}
                </MenuSection>
            );
        }

        return menuSection;
    }

    render() {
        const {
            location,
            environments,
            activeEnvironment,
            isMobile,
            onToggleSidebar,
            setNavbarHover,
            isCollapsed,
            pendingTransactionsQuantity,
            availableFeatures,
        } = this.props;
        // const forms = null;

        // Desktop
        const desktopMenuSection = (
            <MenuItem
                location={location}
                titleKey="menu.desktop"
                key="menu.desktop"
                linkTo="/desktop"
                image="images/ui-icons/menu/ui-menu-escritorio.svg"
                isMobile={isMobile}
            />
        );

        // Accounts
        const accountMenuSection = this.constructMenuSection(
            activeEnvironment,
            "accounts",
            "images/ui-icons/menu/ui-menu-cuentas.svg",
            location,
        );

        // Echeqs
        const echeqsMenuSection = this.constructMenuSection(activeEnvironment, "echeqs", "images/echeqs.svg", location);

        // Loans
        const loanMenuSection = this.constructMenuSection(
            activeEnvironment,
            "loans",
            "images/ui-icons/menu/ui-menu-prestamos.svg",
            location,
        );

        // Comex
        const comexMenuSection = this.constructMenuSection(
            activeEnvironment,
            "comex",
            "images/ui-icons/menu/ui-menu-comercioext.svg",
            location,
        );

        // Transfers
        const transferMenuSection = this.constructMenuSection(
            activeEnvironment,
            "transfers",
            "images/ui-icons/menu/ui-menu-transferencias.svg",
            location,
        );
        /*

        transferMenuItems.push(
            <MenuFormItem
                link="/form/transferThirdParties"
                key="transferThirdParties"
                name={i18n.get("menu.transfers.thirdParties")}
                hasPermission={hasAccounts && !!activeEnvironment.permissions.transferThirdParties}
            />,
        );

        transferMenuItems.push(
            <MenuFormItem
                link="/form/transferLocal"
                key="transferLocal"
                name={i18n.get("menu.transfers.local")}
                hasPermission={hasAccounts && !!activeEnvironment.permissions.transferLocal}
            />,
        );

        transferMenuItems.push(
            <MenuFormItem
                link="/form/transferForeign"
                key="transferForeign"
                name={i18n.get("menu.transfers.foreign")}
                hasPermission={hasAccounts && !!activeEnvironment.permissions.transferForeign}
            />,
        );

        forms = activeEnvironment.forms.transfers;
        transferMenuItems = transferMenuItems.concat(this.constructMenuFormItems(forms));
        */
        // InvoiceMiPyME
        const invoiceMipymeMenuSection = this.constructMenuSection(
            activeEnvironment,
            "invoiceMiPyME",
            "images/ui-icons/menu/ui-menu-mipyme.svg",
            location,
        );

        // Investments
        const investmentMenuSection = this.constructMenuSection(
            activeEnvironment,
            "investments",
            "images/ui-icons/menu/ui-menu-inversiones.svg",
            location,
        );
        /*
         Payments
         let paymentMenuSection = null;
         const paymentMenuItems = [];
        /*
        paymentMenuItems.push(
            <MenuItem
                titleKey="menu.payments.loan"
                key="menu.payments.loan"
                linkTo="/loansPayment/list"
                hasPermission={hasAccounts && !!activeEnvironment.permissions.payLoan}
            />,
        );

        paymentMenuItems.push(
            <MenuItem
                titleKey="menu.payments.thirdPartiesLoan"
                key="menu.payments.thirdPartiesLoan"
                linkTo="/form/payThirdPartiesLoan"
                hasPermission={hasAccounts && !!activeEnvironment.permissions.payThirdPartiesLoan}
            />,
        );

        paymentMenuItems.push(
            <MenuItem
                titleKey="menu.payments.creditCard"
                key="menu.payments.creditCard"
                linkTo="/creditCardsPayment/list"
                hasPermission={hasAccounts && !!activeEnvironment.permissions.payCreditCard}
            />,
        );

        paymentMenuItems.push(
            <MenuItem
                titleKey="menu.payments.thirdPartiesCreditCard"
                key="menu.payments.thirdPartiesCreditCard"
                linkTo="/form/payThirdPartiesCreditCard"
                hasPermission={hasAccounts && !!activeEnvironment.permissions.payThirdPartiesCreditCard}
            />,
        );

        paymentMenuItems.push(
            <MenuItem
                titleKey="menu.multiline.salaryPayment"
                key="menu.multiline.salaryPayment"
                linkTo="/form/salaryPayment"
                hasPermission={hasAccounts && !!activeEnvironment.permissions.salaryPayment}
            />,
        );

        forms = activeEnvironment.forms.payments;
        paymentMenuItems = paymentMenuItems.concat(this.constructMenuFormItems(forms));

         if (paymentMenuItems.length > 0) {
         const paymentMenuSection = (
                 <MenuSection
                     titleKey="menu.payments"
                     key="menu.payments"
                     image="images/ui-icons/menu/ui-menu-pagos.svg"
                     isSidebarHovered={isSidebarHovered}>
                     {paymentMenuItems}
                 </MenuSection>
             );
         }
        */
        const paymentMenuSection = this.constructMenuSection(
            activeEnvironment,
            "payments",
            "images/ui-icons/menu/ui-menu-pagos.svg",
            location,
        );

        // Position
        const positionMenuItem = (
            <MenuItem
                titleKey="menu.position"
                key="menu.position"
                onClick={this.goJump}
                image="images/ui-icons/menu/ui-menu-posicion.svg"
                hasPermission={!!activeEnvironment.permissions.position}
                featureEnabled={!!availableFeatures?.["position"]}
                isMobile={isMobile}
            />
        );

        // Transactions
        const transactionsMenuSection = (
            <MenuItem
                location={location}
                titleKey="menu.transactions"
                key="menu.transactions"
                linkTo="/transactions/list"
                image="images/ui-icons/menu/ui-menu-historial.svg"
                featureEnabled={!!availableFeatures?.["transactions.list"]}
                isMobile={isMobile}
            />
        );

        // Pending dispatch
        const pendingDispatch = (
            <MenuItem
                location={location}
                titleKey="menu.pendingDispatch"
                key="menu.pendingDispatch"
                linkTo="/pendingTransaction/list"
                image="images/ui-icons/menu/ui-menu-transac-pendientes.svg"
                notificationCount={pendingTransactionsQuantity}
                notificationCountTextID="menu.pendingDispatch.badge"
                featureEnabled={!!availableFeatures?.["transactions.list"]}
                isMobile={isMobile}
            />
        );

        // Settings
        const settingsMenuItem = isMobile ? (
            <MenuItem
                location={location}
                titleKey="menu.settings.mobile"
                key="menu.settings.mobile"
                linkTo="/settings"
                image="images/ui-icons/menu/ui-menu-config-personal.svg"
                isMobile={isMobile}
            />
        ) : (
            <MenuItem
                location={location}
                titleKey="menu.settings"
                key="menu.settings"
                linkTo="/settings"
                image="images/ui-icons/menu/ui-menu-config-personal.svg"
                isMobile={isMobile}
            />
        );

        // Change environment
        const changeEnvironment = isMobile && (
            <MenuItem
                location={location}
                title={activeEnvironment.name}
                key="menu.chageEnvironment"
                linkTo="/settings/changeEnvironment"
                image={`images/${activeEnvironment.type}.svg`}
                isMobile={isMobile}
                changeEnvironmentOption={environments && Object.keys(environments).length > 1}
            />
        );

        // Help
        const help = isMobile && (
            <MenuItem
                location={location}
                titleKey="menu.help"
                key="menu.help"
                onClick={this.goHelp}
                image="images/ui-icons/menu/ui-menu-ayuda.svg"
                isMobile={isMobile}
            />
        );

        // Token
        const token = (
            <MenuItem
                location={location}
                titleKey="menu.token"
                key="menu.token"
                linkTo="/registerToken"
                image="images/ui-icons/menu/ui-menu-token.svg"
                isMobile={isMobile}
            />
        );

        // Logout
        const logout = (
            <MenuItem
                location={location}
                titleKey="global.logout"
                key="global.logout"
                onClick={this.renderModalLogout}
                image="images/ui-icons/menu/ui-menu-cerrarsesion.svg"
                isMobile={isMobile}
            />
        );

        // Administration
        // const administrationMenuSection = this.buildAdministrationMenu(location);
        const versionWeb = process.env.REACT_APP_VERSION ? `v${process.env.REACT_APP_VERSION}` : "";
        const buildWeb = window.APP_VERSION ? ` (Build ${window.APP_VERSION})` : "";

        return (
            <div
                onMouseOver={(e) => !isMobile && e.target.dataset.tooltip && this.showTooltip(e.target)}
                onFocus={(e) => !isMobile && e.target.dataset.tooltip && this.showTooltip(e.target)}
                onMouseOut={() => this.hideTooltip()}
                onBlur={() => this.hideTooltip()}
                onClick={(e) => {
                    if (isMobile) {
                        return e.target.dataset.tooltip ? this.showTooltip(e.target) : this.hideTooltip();
                    }
                    return null;
                }}>
                <Nav
                    className="menu-list"
                    role="menu"
                    onMouseEnter={() => (setNavbarHover ? setNavbarHover(true) : {})}
                    onMouseLeave={() => (setNavbarHover ? setNavbarHover(false) : {})}>
                    {this.state.tooltipDesktop.showTooltip && !isMobile && (
                        <TooltipUi target={this.state.tooltipDesktop.tooltipTarget} position="right" />
                    )}
                    {isMobile && <FirstLevelItem>{changeEnvironment}</FirstLevelItem>}
                    {isMobile && <FirstLevelItem>{settingsMenuItem}</FirstLevelItem>}
                    {!isMobile && <ToggleSidebar isSidebarCollapsed={isCollapsed} onToggle={onToggleSidebar} />}
                    <FirstLevelItem>{desktopMenuSection}</FirstLevelItem>
                    <FirstLevelItem>{accountMenuSection}</FirstLevelItem>
                    <FirstLevelItem>{echeqsMenuSection}</FirstLevelItem>
                    <FirstLevelItem>{transferMenuSection}</FirstLevelItem>
                    <FirstLevelItem>{paymentMenuSection}</FirstLevelItem>
                    <FirstLevelItem>{invoiceMipymeMenuSection}</FirstLevelItem>
                    <FirstLevelItem>{loanMenuSection}</FirstLevelItem>
                    <FirstLevelItem>{investmentMenuSection}</FirstLevelItem>
                    <FirstLevelItem>{positionMenuItem}</FirstLevelItem>
                    <FirstLevelItem>{comexMenuSection}</FirstLevelItem>
                    {activeEnvironment && activeEnvironment.type !== CORPORATE_GROUP_ENVIRONMENT_TYPE && (
                        <FirstLevelItem>{pendingDispatch}</FirstLevelItem>
                    )}
                    <FirstLevelItem>{transactionsMenuSection}</FirstLevelItem>
                    {isMobile && <FirstLevelItem>{help}</FirstLevelItem>}
                    <FirstLevelItem>{token}</FirstLevelItem>
                    <FirstLevelItem>{logout}</FirstLevelItem>
                    <FirstLevelItem>
                        <p className="text-muted app-version">
                            {/* eslint-disable no-undef */}
                            {versionWeb} {buildWeb}
                        </p>
                    </FirstLevelItem>
                </Nav>
            </div>
        );
    }
}
function FirstLevelItem({ children }) {
    return (
        <li role="menuitem" className="menu-list-item">
            {children}
        </li>
    );
}

FirstLevelItem.propTypes = {
    children: node.isRequired,
};

const mapStateToProps = (state) => ({
    location: state.router.location,
    environments: sessionSelectors.getEnvironments(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    lang: i18nSelectors.getLang(state),
    isAdministrator: sessionSelectors.isAdministrator(state),
    pendingTransactionsQuantity: transactionsSelectors.getPendingTransactionsQuantity(state),
    availableFeatures: sessionSelectors.getAvailableFeatures(state),
    hasAccountQr: accountsSelectors.getAccountQr(state),
});

export default connect(mapStateToProps)(Menu);
