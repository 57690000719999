import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { bool, string, func, arrayOf, objectOf, any, oneOfType } from "prop-types";
import Yup from "yup";

import { actions as filterActions } from "reducers/filters";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as sessionSelectors } from "reducers/session";

import * as i18n from "util/i18n";
import * as config from "util/config";
import defaultDateFromList from "util/defaultDateFromList";
import moment from "moment";

import Button from "pages/_components/Button";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { Field, Form, withFormik } from "formik";
import { resizableRoute } from "pages/_components/Resizable";
import { clearFiltersAndSubmit } from "util/form";
import classNames from "classnames";

class PendingFilters extends Component {
    static propTypes = {
        fetching: bool,
        idForm: string.isRequired,
        isMobile: bool.isRequired,
        transactionTypes: oneOfType([arrayOf(any), objectOf(any)]).isRequired,
        setFieldValue: func.isRequired,
        setValues: func.isRequired,
        currentLang: string.isRequired,
        submitForm: func.isRequired,
        selectedQuickFilter: string.isRequired,
        handleSetSelectedQuickFilter: func.isRequired,
        dispatch: func.isRequired,
        handleSetActivityGroupSelected: func.isRequired,
        handleSetTransactionsSelected: func.isRequired,
        activitiesGroup: arrayOf(any).isRequired,
    };

    static defaultProps = {
        fetching: false,
    };

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this.myRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    getSignStates = () => {
        const states = config.get("transactions.pending.sign.states", "Todos|SIGNED|NOT_SIGNED|DRAFT").split("|");
        const valuesFinal = states.map((state) => ({
            id: state,
            label: i18n.get(`transaction.pending.status.${state}`),
        }));
        return valuesFinal;
    };

    getTransactionTypes = () => {
        const { transactionTypes, activitiesGroup, selectedQuickFilter } = this.props;
        const values = transactionTypes;

        const filteredQuickFilterGroup =
            selectedQuickFilter && activitiesGroup.find((activity) => activity.id === selectedQuickFilter);
        let filteredValues;
        const customAllOption = { id: "", label: `Todos (${i18n.get(`configuration.${selectedQuickFilter}`)})` };

        if (filteredQuickFilterGroup) {
            if (selectedQuickFilter === "transactions.pending.quickFilter.others") {
                const nonOtherTransactionTypesIds = activitiesGroup.map((activity) => activity.values).flat();
                const filteredOthersTransactionsTypes = transactionTypes.filter(
                    (type) =>
                        !nonOtherTransactionTypesIds.includes(type.id) &&
                        type.id !== "all" &&
                        type.id !== "transactions.multiple.signatures.send",
                );
                filteredValues = [customAllOption, ...filteredOthersTransactionsTypes];
                return filteredValues;
            }
            const groupTypes = values.filter((type) => filteredQuickFilterGroup.values.includes(type.id));
            filteredValues = [customAllOption, ...groupTypes];
            return filteredValues;
        }

        if (values.find((item) => item.id === "all") === undefined) {
            values.unshift({ id: "all", label: "Todos" });
        }

        return values;
    };

    handleOnChangeTransactionType = () => {
        const { setFieldValue } = this.props;
        setFieldValue("keyWord", "");
    };

    handleQuickFilters = (filter) => {
        let filterValues = filter.values;
        let others = false;
        if (filterValues?.length === 1) {
            if (filterValues[0] === "*") {
                filterValues = null;
            } else if (filterValues[0] === "others") {
                others = true;
                const finalFilters = config.getArrayObjectsByPortionId("transactions.pending.quickFilter.");
                filterValues = finalFilters
                    .map((itemFilter) => itemFilter.values)
                    .reduce((acc, val) => acc.concat(val), [])
                    .filter((value) => value !== "others");
            }
        }

        const {
            dispatch,
            handleSetSelectedQuickFilter,
            handleSetActivityGroupSelected,
            handleSetTransactionsSelected,
            setValues,
        } = this.props;
        const filters = {
            quickFilterActivities: filterValues,
            pageNumber: 1,
            minAmount: null,
            others,
        };
        setValues({
            quickFilterActivities: filterValues,
            transactionType: null,
            state: ["Todos"],
        });
        handleSetTransactionsSelected([]);
        handleSetSelectedQuickFilter(filter.id);
        handleSetActivityGroupSelected(filter.id);
        dispatch(transactionsActions.loadListRequest(filters, true, false));
    };

    handleClearFilters = () => {
        const {
            setValues,
            submitForm,
            handleSetSelectedQuickFilter,
            handleSetActivityGroupSelected,
            handleSetTransactionsSelected,
        } = this.props;

        handleSetSelectedQuickFilter("");
        handleSetActivityGroupSelected("");
        handleSetTransactionsSelected([]);

        clearFiltersAndSubmit(
            () =>
                setValues({
                    transactionType: ["all"],
                    state: ["Todos"],
                    dateFrom: defaultDateFromList().pendingTransactions,
                    dateTo: moment(),
                    pageNumber: 1,
                }),
            submitForm,
        );
    };

    getQuickFilters = () => {
        const { selectedQuickFilter } = this.props;
        const finalFilters = config.getArrayObjectsByPortionId("transactions.pending.quickFilter.");

        const order = [
            "transactions.pending.quickFilter.transfers",
            "transactions.pending.quickFilter.echeq",
            "transactions.pending.quickFilter.suppliers",
            "transactions.pending.quickFilter.investments",
            "transactions.pending.quickFilter.others",
        ];

        const orderedFinalFilters = finalFilters.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));

        const quickFiltersButtons = orderedFinalFilters.map((filter) => (
            <Button
                className={classNames(`btn-chip ${filter.id}`, {
                    "is-active": selectedQuickFilter === filter.id,
                })}
                bsStyle="outline"
                key={filter.id}
                type="button"
                label={`configuration.${filter.id}`}
                onClick={() => this.handleQuickFilters(filter)}
                image={selectedQuickFilter === filter.id ? "ui-icons/ui-echeq-active-arrow.svg" : ""}
            />
        ));

        return quickFiltersButtons;
    };

    render() {
        const { idForm, fetching, isMobile, currentLang } = this.props;

        return (
            <>
                <div ref={this.myRef} className="filters-container pending-transactions">
                    <Form className="form-content">
                        <div className="filters-row quickFilters">{this.getQuickFilters()}</div>
                        <div className="filters-row">
                            <Field
                                component={FormFieldsComponents.Selector}
                                optionList={this.getTransactionTypes()}
                                key="transactionType"
                                name="transactionType"
                                idField="transactionType"
                                renderAs="combo"
                                mode="edit"
                                isRequired
                                idActivity={`${idForm}.send`}
                                idForm={idForm}
                                searchable
                                onChange={this.handleOnChangeTransactionType}
                                lang={currentLang}
                            />
                            <Field
                                component={FormFieldsComponents.Selector}
                                optionList={this.getSignStates()}
                                key="state"
                                name="state"
                                idField="state"
                                renderAs="combo"
                                mode="edit"
                                isRequired
                                idActivity={`${idForm}.send`}
                                idForm={idForm}
                                lang={currentLang}
                            />
                            <Button
                                block={isMobile}
                                bsStyle="primary"
                                label="product.filters.filter"
                                loading={fetching}
                                type="submit"
                            />

                            <Button
                                block={isMobile}
                                bsStyle="outline"
                                className="btn-clear-filters"
                                label="product.filters.clearFilters"
                                image="images/ui-icons/ui-clear-filters.svg"
                                loading={fetching}
                                onClick={this.handleClearFilters}
                            />
                        </div>
                    </Form>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    filtersSaved: transactionsSelectors.getFilters(state),
    transactionTypes: sessionSelectors.getTransactionTypes(state),
    currentLang: i18nSelectors.getLang(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            state: props.filtersSaved && props.filtersSaved.state ? props.filtersSaved.state : ["Todos"],
            pageNumber: props.filtersSaved ? props.filtersSaved.pageNumber : 1,
            transactionType:
                props.filtersSaved && props.filtersSaved.transactionType ? props.filtersSaved.transactionType : ["all"],
            quickFilterActivities:
                props.filtersSaved && props.filtersSaved.quickFilterActivities
                    ? props.filtersSaved.quickFilterActivities
                    : null,
        }),
        validationSchema: () => Yup.lazy(() => Yup.object().shape({})),
        handleSubmit: ({ ...values }, formikBag) => {
            const {
                dispatch,
                isDesktop,
                onlyPendings,
                resetSelectedFilter,
                handleSetTransactionsSelected,
            } = formikBag.props;
            let newFilters = { ...values };
            const isDraft = values.state[0] === "DRAFT";

            if (values.quickFilterActivities && values.transactionType && values.transactionType[0] !== "all") {
                newFilters = { ...newFilters, quickFilterActivities: null };
            }

            if (isDesktop) {
                dispatch(
                    transactionsActions.loadListRequest(
                        newFilters,
                        isDraft ? false : onlyPendings || false,
                        false,
                        false,
                    ),
                );
                if (resetSelectedFilter) {
                    resetSelectedFilter();
                }
            } else {
                dispatch(
                    transactionsActions.loadListRequest(
                        newFilters,
                        isDraft ? false : onlyPendings || false,
                        false,
                        false,
                    ),
                );
                dispatch(filterActions.toggleShowFilters());
            }
            handleSetTransactionsSelected([]);
        },
    }),
)(resizableRoute(PendingFilters));
