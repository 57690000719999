import React, { Component } from "react";
import moment from "moment";
import flowRight from "lodash/flowRight";
import { bool, func, string } from "prop-types";

import formField from "pages/forms/_components/_fields/_commons/formField";
import DatePicker from "pages/_components/fields/datepicker";
import withFocus from "pages/_components/withFocus";

import { get } from "util/config";
import { format } from "date-fns";
import * as i18n from "util/i18n";

class Date extends Component {
    static propTypes = {
        dateFormat: string.isRequired,
        editing: bool.isRequired,
        setValue: func.isRequired,
        name: string.isRequired,
        onBlur: func.isRequired,
        placeholder: string,
        toggleIsFocused: func.isRequired,
        value: string.isRequired,
        startDate: string,
        disabled: bool,
        onChange: func,
    };

    static defaultProps = {
        disabled: false,
        onChange: null,
        startDate: "",
        placeholder: "",
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            ...prevState,
            startDate: nextProps.value ? moment(nextProps.value) : null,
        };
    }

    constructor(props) {
        super(props);

        this.state = {};
    }

    handleDateChange = (date) => {
        const { setValue, onChange } = this.props;
        setValue(date);
        if (onChange) {
            onChange(date);
        }
    };

    render() {
        const {
            name,
            value,
            editing,
            dateFormat,
            toggleIsFocused,
            placeholder,
            onBlur,
            startDate,
            disabled,
            ...rest
        } = this.props;

        if (editing) {
            return (
                <div
                    className={`input-group input-group-datepicker ${disabled ? "disabled" : ""}`}
                    onFocus={toggleIsFocused}
                    onBlur={toggleIsFocused}>
                    <DatePicker
                        dateFormat={dateFormat || get("frontend.shortDateFormat").toUpperCase()}
                        className="form-control"
                        name={name}
                        readOnly={disabled}
                        onChange={this.handleDateChange}
                        selected={value ? moment(value) : null}
                        autoComplete="off"
                        startDate={startDate ? moment(startDate) : null}
                        placeholderText={placeholder}
                        onBlur={onBlur}
                        onKeyDown={(e) => e.preventDefault()}
                        isClearable={false}
                        {...rest}
                        disabled={disabled}
                    />
                </div>
            );
        }

        return (
            <span>
                {value
                    ? format(value, dateFormat || get("frontend.shortDateFormat").toUpperCase())
                    : i18n.get("datepicker.noDate")}
            </span>
        );
    }
}

export default flowRight(withFocus, formField({ formClass: "form-group--datepicker" }))(Date);
