/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from "classnames";
import Button from "pages/_components/Button";
import { arrayOf, bool, element, node, string } from "prop-types";
import React, { Component, createRef } from "react";
import { config, Spring } from "react-spring";

class Dropdown extends Component {
    node = createRef();

    static propTypes = {
        children: arrayOf(element).isRequired,
        /**
         * Align the menu to the right side of the Dropdown
         */
        pullRight: bool,
        fetching: bool,
        buttonClass: string,
        label: string,
        image: string,
        closeImage: string,
        bsStyle: string,
        dropdownButtonContent: node,
        isCollapsed: bool,
        isSidebarHovered: bool,
        isMenuDropdown: bool,
        disabled: bool,
    };

    static defaultProps = {
        pullRight: false,
        bsStyle: null,
        fetching: false,
        buttonClass: null,
        label: null,
        image: null,
        closeImage: null,
        dropdownButtonContent: null,
        isCollapsed: false,
        isSidebarHovered: false,
        isMenuDropdown: false,
        disabled: false,
    };

    state = {
        isOpen: false,
        navIndex: 0,
    };

    componentDidUpdate(prevProps) {
        const { isOpen } = this.state;
        const { isCollapsed, isSidebarHovered } = this.props;

        /* eslint-disable react/no-did-update-set-state */
        if (!prevProps.isCollapsed && isCollapsed && isOpen) {
            this.setState({ isOpen: false });
        }

        if (isCollapsed && isOpen && !isSidebarHovered) {
            this.setState({ isOpen: false });
        }
        this.removeListeners();
        document.addEventListener("click", this.listener);
        document.addEventListener("touchstart", this.listener);
    }

    componentWillUnmount() {
        this.removeListeners();
    }

    listener = ({ target }) => {
        const { isOpen } = this.state;
        const { current } = this.node;
        const { isMenuDropdown } = this.props;

        if (!current || current.contains(target)) {
            return;
        }

        if (isOpen) {
            if (isMenuDropdown) {
                setTimeout(() => {
                    this.setState({ isOpen: false });
                }, 50);
            } else {
                this.setState({ isOpen: false });
            }
        }
    };

    toggleOpen = () => {
        const { isOpen, onClick } = this.state;
        if (onClick) {
            onClick();
        }
        this.setState({
            isOpen: this.node.current ? !isOpen : true,
            navIndex: 0,
        });
    };

    removeListeners = () => {
        document.removeEventListener("click", this.listener);
        document.removeEventListener("touchstart", this.listener);
    };

    onChildFocus = (param) => {
        this.setState({ navIndex: param });
    };

    render() {
        const {
            image,
            closeImage,
            pullRight,
            children,
            fetching,
            buttonClass,
            label,
            bsStyle,
            dropdownButtonContent,
            isMenuDropdown,
            disabled,
        } = this.props;
        const { isOpen, navIndex } = this.state;
        const childrenLength = React.Children.toArray(children).length;
        const isOverflow = childrenLength > 10 ? "auto" : "hidden";

        const keyPressHandler = (ev) => {
            let aux = navIndex;
            if (ev.shiftKey && ev.key === "Tab") {
                aux -= 1;
            } else if (ev.key === "Tab") {
                aux += 1;
            }

            if (aux >= childrenLength || aux < 0) {
                this.setState({ isOpen: false });
            }
        };

        return (
            <div className="dropdown" ref={this.node}>
                <Button
                    image={!isOpen ? image : closeImage || image}
                    loading={fetching}
                    onClick={this.toggleOpen}
                    block={false}
                    className={buttonClass}
                    label={label}
                    bsStyle={bsStyle}
                    aria-haspopup
                    aria-expanded={isOpen}
                    onKeyDown={keyPressHandler}
                    disabled={disabled}>
                    {dropdownButtonContent}
                </Button>

                <Spring
                    config={config.stiff}
                    from={{
                        height: 0,
                    }}
                    to={{
                        height: isOpen ? "auto" : 0,
                        borderWidth: isOpen ? 1 : 0,
                        maxHeight: !isMenuDropdown && isOverflow && "80vh",
                        overflow: !isMenuDropdown && isOverflow,
                    }}>
                    {(styles) => (
                        <ul
                            style={styles}
                            className={classNames("dropdown__menu", {
                                "dropdown__menu--right": pullRight,
                                "ui-scrollbar": !isMenuDropdown,
                            })}>
                            {React.Children.map(children, (child, ix) => (
                                <li
                                    className="dropdown__item"
                                    onClick={pullRight ? this.toggleOpen : () => {}}
                                    onKeyDown={keyPressHandler}>
                                    {React.cloneElement(child, {
                                        ...child.props,
                                        ix,
                                        onFocus: () => {
                                            this.onChildFocus(ix);
                                        },
                                    })}
                                </li>
                            ))}
                        </ul>
                    )}
                </Spring>
            </div>
        );
    }
}

export default Dropdown;
