import React, { useEffect, useRef, useState } from "react";
import { any, bool, func, node, objectOf, string } from "prop-types";

import "./_styles.scss";

const TooltipV2 = ({ onMouseLeave, target, position, tooltipContent, isMobile, setShowTooltip }) => {
    const tooltipRef = useRef(null);
    const [tooltipDataPosition, setTooltipDataPosition] = useState({
        top: 0,
        left: 0,
        opacity: 0,
    });

    const updateTooltipPosition = () => {
        const refPositionData = target.getBoundingClientRect();
        const tooltipPosition = tooltipRef.current.getBoundingClientRect();

        let styles = {};

        switch (position) {
            case "top":
                styles = {
                    top: refPositionData?.y - tooltipPosition?.height,
                    left: refPositionData?.x + refPositionData?.width / 2 - tooltipPosition?.width / 2,
                    bottom: tooltipPosition?.bottom,
                };
                break;

            case "right":
                styles = {
                    top: refPositionData?.y + refPositionData?.height / 2 - tooltipPosition?.height / 2,
                    left: refPositionData?.x + refPositionData?.width,
                    bottom: tooltipPosition?.bottom,
                };
                break;

            case "left":
                styles = {
                    top: refPositionData?.y + refPositionData?.height / 2 - tooltipPosition?.height / 2,
                    left: refPositionData?.x - tooltipPosition?.width,
                    bottom: tooltipPosition?.bottom,
                };
                break;

            case "bottom":
                styles = {
                    top: refPositionData?.y + refPositionData?.height,
                    left: refPositionData?.x + refPositionData?.width / 2 - tooltipPosition?.width / 2,
                    bottom: tooltipPosition?.bottom,
                };
                break;

            case "top-right":
                styles = {
                    top: refPositionData?.y - tooltipPosition?.height,
                    left: refPositionData?.x + refPositionData?.width - tooltipPosition?.width,
                    bottom: tooltipPosition?.bottom,
                };
                break;

            case "top-left":
                styles = {
                    top: refPositionData?.y - tooltipPosition?.height,
                    left: refPositionData?.x,
                    bottom: tooltipPosition?.bottom,
                };
                break;

            case "bottom-right":
                styles = {
                    top: refPositionData?.y + refPositionData?.height,
                    left: refPositionData?.x + refPositionData?.width - tooltipPosition?.width,
                    bottom: tooltipPosition?.bottom,
                };
                break;

            case "bottom-left":
                styles = {
                    top: refPositionData?.y + refPositionData?.height,
                    left: refPositionData?.x,
                    bottom: tooltipPosition?.bottom,
                };
                break;

            case "right-top":
                styles = {
                    top: refPositionData?.y,
                    left: refPositionData?.x + refPositionData?.width,
                    bottom: tooltipPosition?.bottom,
                };
                break;

            case "right-bottom":
                styles = {
                    top: refPositionData?.y + refPositionData?.height - tooltipPosition?.height,
                    left: refPositionData?.x + refPositionData?.width,
                    bottom: tooltipPosition?.bottom,
                };
                break;

            case "left-top":
                styles = {
                    top: refPositionData?.y,
                    left: refPositionData?.x - tooltipPosition?.width,
                    bottom: tooltipPosition?.bottom,
                };
                break;

            case "left-bottom":
                styles = {
                    top: refPositionData?.y + refPositionData?.height - tooltipPosition?.height,
                    left: refPositionData?.x - tooltipPosition?.width,
                    bottom: tooltipPosition?.bottom,
                };
                break;

            default:
                break;
        }

        if (styles.top !== 0 && (styles.top < 50 || styles.bottom > window.innerHeight)) {
            return setShowTooltip(false);
        }

        return setTooltipDataPosition(styles);
    };

    useEffect(() => {
        updateTooltipPosition();

        const handleScroll = () => {
            updateTooltipPosition();
        };

        const wrapper = isMobile
            ? document.querySelector(".view-wrapper .view-page")
            : document.querySelector(".view-wrapper");

        wrapper.addEventListener("scroll", handleScroll);

        return () => {
            wrapper.removeEventListener("scroll", handleScroll);
        };
    }, [target, position]);

    return (
        <div
            ref={tooltipRef}
            style={tooltipDataPosition}
            className={`tooltip-v2 ${position}`}
            onMouseLeave={onMouseLeave}>
            <div className="tooltip-v2-arrow" />
            <div className="tooltip-v2-content">
                {React.Children.map(tooltipContent, (child) =>
                    React.cloneElement(child, {
                        // className: child.props.className
                        //     ? `${child.props.className} tooltip-v2-item`
                        //     : 'tooltip-v2-item',
                    }),
                )}
            </div>
        </div>
    );
};

TooltipV2.propTypes = {
    onMouseLeave: func.isRequired,
    target: objectOf(any).isRequired,
    position: string.isRequired,
    tooltipContent: node.isRequired,
    setShowTooltip: func.isRequired,
    isMobile: bool.isRequired,
};

export default TooltipV2;
