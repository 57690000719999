import React, { useEffect, useRef } from "react";
import { arrayOf, bool, func, shape } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import Yup from "yup";
import classNames from "classnames";

import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import * as i18nUtils from "util/i18n";
import * as utils from "util/general";

import { Field, Form, withFormik } from "formik";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Button from "pages/_components/Button";
import BigModal from "pages/_components/BigModal/BigModal";
import Loader from "pages/_components/Loader";
import Credential from "pages/_components/fields/credentials/Credential";
import Notification from "pages/_components/Notification";
import FormattedAmount from "pages/_components/FormattedAmount";

const SignMultipleTransactionsModal = ({
    transactionsToSign,
    noPendingTransactions,
    dispatch,
    isMobile,
    fetching,
    showSignMultipleTransactionsModal,
    isSubmitting,
    setFieldValue,
}) => {
    const formRef = useRef();
    const contentRef = useRef();

    useEffect(() => {
        if (isSubmitting) {
            formRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [isSubmitting]);

    useEffect(() => {
        if (transactionsToSign.length > 0) {
            setFieldValue("otp", "");
        }
    }, [transactionsToSign]);

    useEffect(
        () => () => {
            dispatch(transactionsActions.resetTransactionDataToSign());
        },
        [],
    );

    const handleShowSignMultipleTransactionsModal = () => {
        dispatch(transactionsActions.setShowSignMultipleTransactionsModal(false));
        dispatch(transactionsActions.resetTransactionDataToSign());
        if (transactionsToSign.length === 0 || noPendingTransactions.length > 0) {
            window.location.reload();
        }
    };

    const deleteSingleTransaction = (idTransaction) => {
        const transactionsIds = transactionsToSign.map((transaction) => transaction.idTransaction);

        const newTransactions = transactionsIds.filter((transaction) => transaction !== idTransaction);

        dispatch(transactionsActions.loadTransactionDataToSign(newTransactions));
    };

    const renderTransactionDetail = (transaction) => {
        const { idTransaction, idTransactionStatus, activityName, totalAmount, data } = transaction;
        const { amount } = data;

        let renderAmount = false;
        if (amount?.currency && totalAmount) {
            renderAmount = true;
        }

        return (
            <div
                key={idTransaction}
                className={classNames("transaction-item-details", {
                    "ui-text-error": idTransactionStatus !== "PENDING",
                })}>
                <div className="details">
                    <div className="data-wrapper">
                        <div className="data-label">{activityName}</div>
                    </div>
                    {renderAmount && (
                        <div className="data-wrapper">
                            <div className="data-label">
                                {i18nUtils.get("transactions.multiple.sign.amount.label")}:
                            </div>
                            <FormattedAmount currency={data.amount.currency} quantity={totalAmount} />
                        </div>
                    )}
                    <div className="data-wrapper">
                        <div className="data-label">
                            {i18nUtils.get("transactions.multiple.sign.transactionStatus.label")}:
                        </div>
                        <div
                            className={`data-text ui-text-uppercase ${utils.getTransactionStatusColor(
                                idTransactionStatus,
                            )}`}>
                            {i18nUtils.get(`transaction.status.${idTransactionStatus}`)}
                        </div>
                    </div>
                </div>
                {idTransactionStatus === "PENDING" && (
                    <div className="actions">
                        <Button
                            label="global.delete"
                            className="btn-icon-only btn-delete"
                            onClick={() => deleteSingleTransaction(idTransaction)}
                            image="images/ui-icons/ui-delete.svg"
                        />
                    </div>
                )}
            </div>
        );
    };
    const renderForm = () => {
        const hasScrollbar = contentRef?.current?.scrollHeight > contentRef?.current?.clientHeight;

        return (
            <Form className="form-content">
                <Notification scopeToShow="signMultipleTransactions" />
                <div className="title" ref={formRef}>
                    <Image
                        src={`images/coloredIcons/${transactionsToSign.length ? "signColor.svg" : "failure.svg"}`}
                        alt=""
                        className=""
                    />
                    <I18n
                        id={
                            transactionsToSign.length
                                ? "forms.signMultipleTransaction.modaltitle"
                                : "forms.signMultipleTransaction.modal.noTransactions.error"
                        }
                        TRANSACTIONS={transactionsToSign.length}
                        component="h3"
                    />
                </div>
                <div
                    className={classNames("content", {
                        withScrollbar: hasScrollbar,
                    })}
                    ref={contentRef}>
                    {transactionsToSign.length > 0 && (
                        <div className="transaction-item-container">
                            {transactionsToSign.map((transaction) => renderTransactionDetail(transaction))}
                        </div>
                    )}
                    {noPendingTransactions.length > 0 && (
                        <div className="transaction-item-container noPending">
                            <hr className="ui-mt-7 ui-mb-0" />
                            <I18n
                                id={`forms.signMultipleTransaction.modal.pendingTransactions.warning.${
                                    noPendingTransactions.length > 1 ? "plural" : "singular"
                                }.title`}
                                component="h3"
                            />
                            {noPendingTransactions.map((transaction) => renderTransactionDetail(transaction))}
                            {transactionsToSign.length !== 0 && (
                                <I18n
                                    id="forms.signMultipleTransaction.modal.pendingTransactions.warning.subtitle"
                                    componentProps={{ className: "ui-body-medium" }}
                                />
                            )}
                        </div>
                    )}
                </div>
                {transactionsToSign.length !== 0 && (
                    <div className="token">
                        <Field idForm="form.credential" name="otp" component={Credential} type="otp" />
                        <Button
                            label="forms.signMultipleTransaction.modal.button.label"
                            bsStyle="primary"
                            block
                            type="submit"
                            loading={fetching}
                        />
                    </div>
                )}
            </Form>
        );
    };

    return (
        <BigModal
            className="sign-multiple-transactions-modal"
            showModal={showSignMultipleTransactionsModal}
            handleShowModal={() => {
                handleShowSignMultipleTransactionsModal();
            }}
            modalWidth="548px"
            modalTitle=""
            isMobile={isMobile}>
            {fetching ? <Loader /> : renderForm()}
        </BigModal>
    );
};

SignMultipleTransactionsModal.propTypes = {
    isMobile: bool.isRequired,
    fetching: bool.isRequired,
    showSignMultipleTransactionsModal: bool.isRequired,
    transactionsToSign: arrayOf(shape({})).isRequired,
    noPendingTransactions: arrayOf(shape({})).isRequired,
    dispatch: func.isRequired,
    isSubmitting: bool.isRequired,
    setFieldValue: func.isRequired,
};

const mapStateToProps = (state) => ({
    fetching: transactionsSelectors.getFetching(state),
    transactionsToSign: transactionsSelectors.getTransactionsToSign(state),
    noPendingTransactions: transactionsSelectors.getNoPendingTransactions(state),
    showSignMultipleTransactionsModal: transactionsSelectors.getShowSignMultipleTransactionsModal(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        nableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            otp: "",
        }),
        validationSchema: () =>
            Yup.lazy(() =>
                Yup.object().shape({
                    otp: Yup.string().required(i18nUtils.get(`form.credential.otp.required`)),
                }),
            ),
        handleSubmit: (values, formikBag) => {
            const { otp } = values;
            const { dispatch, transactionsToSign } = formikBag.props;

            const credentials = {
                otp,
            };

            const transactionsIds = transactionsToSign.map((transaction) => transaction.idTransaction);

            const dataToSend = {
                transactions: transactionsIds,
                credentials,
                formikBag,
            };

            dispatch(transactionsActions.signMultipleTransactionsRequest(dataToSend));
        },
    }),
)(SignMultipleTransactionsModal);
