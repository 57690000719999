import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { func, node, number, oneOf, oneOfType, shape, bool, string } from "prop-types";
import TooltipV2 from "./TooltipV2";

const OverlayV2 = ({ children, position, tooltipContent, delay, isMobile, idItem }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [target, setTarget] = useState();
    const positionRef = useRef(null);

    useEffect(() => {
        // Se carga la referencia a traves del nodo temporal y luego se lo elimina
        const reference = positionRef?.current;

        setTarget(reference?.previousElementSibling);

        reference.remove();
    }, []);

    useEffect(() => {
        if (tooltipContent) {
            document.addEventListener("click", (e) => clickObserver(e));
        }

        return () => {
            document.removeEventListener("click", clickObserver);
        };
    });

    function addDataOverlayToChildren(element) {
        element.setAttribute("data-overlay", idItem);

        Array.from(element.children).forEach((child) => {
            addDataOverlayToChildren(child);
        });
    }

    function removeDataOverlayToChildren(element) {
        element.removeAttribute("data-overlay", idItem);

        Array.from(element.children).forEach((child) => {
            removeDataOverlayToChildren(child);
        });
    }

    function clickObserver(e) {
        if (e.target?.dataset?.overlay !== idItem) {
            setShowTooltip(false);
        }
    }

    const handleMobileClick = (e, childrenOnClick) => {
        if (childrenOnClick) {
            childrenOnClick(e);
        } else if (tooltipContent) {
            addDataOverlayToChildren(target);

            setShowTooltip(true);

            if (!e.target?.dataset?.overlay === idItem) {
                setShowTooltip(false);
            }
        }
    };

    const handleShowTooltip = (e) => {
        if (tooltipContent) {
            addDataOverlayToChildren(target);

            if (e.target?.dataset?.overlay === idItem && !showTooltip) {
                setTimeout(() => {
                    setShowTooltip(true);
                }, delay?.show || 0);
            }
        }
    };

    const handleHideTooltip = (e) => {
        if (!e.relatedTarget?.classList?.value.includes("tooltip-v2") && showTooltip) {
            setTimeout(() => {
                setShowTooltip(false);
            }, delay?.hide || 0);

            removeDataOverlayToChildren(target);
        }
    };

    return (
        <>
            {React.cloneElement(children, {
                onMouseEnter: handleShowTooltip,
                onMouseLeave: handleHideTooltip,
                "data-overlay": true,
                onClick: (e) => handleMobileClick(e, children.props?.onClick),
            })}
            <div style={{ display: "none" }} ref={positionRef} />
            {showTooltip &&
                tooltipContent &&
                ReactDOM.createPortal(
                    <TooltipV2
                        position={position}
                        target={target}
                        tooltipContent={tooltipContent}
                        isMobile={isMobile}
                        setShowTooltip={setShowTooltip}
                        onMouseLeave={() => setShowTooltip(false)}
                    />,
                    document.body,
                )}
        </>
    );
};

OverlayV2.propTypes = {
    children: node.isRequired,
    position: oneOf([
        "top",
        "right",
        "left",
        "bottom",
        "top-right",
        "top-left",
        "bottom-right",
        "bottom-left",
        "right-top",
        "right-bottom",
        "left-top",
        "left-bottom",
    ]),
    tooltipContent: oneOfType([node, func]),
    delay: shape({
        show: number,
        hide: number,
    }),
    isMobile: bool.isRequired,
    idItem: string.isRequired,
};

OverlayV2.defaultProps = {
    position: "bottom",
    tooltipContent: null,
    delay: {
        show: 50,
        hide: 0,
    },
};

export default OverlayV2;
