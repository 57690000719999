import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import { bool, func, shape } from "prop-types";

import { actions as filterActions } from "reducers/filters";

import { Col, Row } from "react-bootstrap";
import Alert from "pages/_components/Alert/Alert";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import Notification from "pages/_components/Notification";
import getSubStatesByState from "util/echeqs";
import Button from "pages/_components/Button";
import classNames from "classnames";
import { actions as eCheqsActions, selectors as eCheqsSelector } from "../../reducers/echeqs";
import { selectors as sessionSelectors } from "../../reducers/session";
import I18n from "../_components/I18n";
import FormattedAmount from "../_components/FormattedAmount";
import MainContainer from "../_components/MainContainer";
import ProductDetail from "../_components/ProductDetail";
import DetailItem from "./DetailItem";
import EcheqActions from "./EcheqActions";
import FormattedDate from "../_components/FormattedDate";
import DetailItemForEndorsement from "./DetailItemForEndorsement";
import DetailItemForAssignment from "./DetailItemForAssignment";
import DetailItemForAval from "./DetailItemForAval";
import EcheqSubStates from "./EcheqSubStates";

import "./_echeqStyles.scss";

const EcheqDetail = ({
    isFetching,
    detail,
    dispatch,
    activeEnvironment,
    history,
    filters,
    isMobile,
    fetchingDownload,
    match,
    echeqSubStates,
}) => {
    const ID_ACTIVITY = "echeqs.detail";
    const { echeqType } = filters;
    const hasPendingTransactions = detail?.relatedPendingTransactions?.length > 0;

    const [collapse, setCollapse] = useState(false);

    useEffect(() => {
        const idEcheq = match?.params?.id;
        if (idEcheq) {
            dispatch(eCheqsActions.cleanBankedCustomerInfo());
            dispatch(eCheqsActions.getEcheqDetailRequest(idEcheq, filters));
        } else {
            dispatch(push("/echeqs"));
        }
    }, []);

    const handleCollapse = () => {
        setCollapse(!collapse);
    };

    const handleClickDownload = (format) => {
        const subStates = getSubStatesByState(detail, echeqSubStates);

        let mappedSubStates = "";
        if (subStates.length > 0) {
            mappedSubStates = `-${subStates.join("-")}`;
        }

        dispatch(eCheqsActions.downloadEcheqMovementDetail(detail, format, mappedSubStates.toUpperCase()));
    };

    const handleBack = () => {
        dispatch(filterActions.setResetFilters(false));
        history.goBack();
    };

    return (
        <>
            <Notification scopeToShow="echeqs/details" />
            <ProductDetail>
                <ProductDetail.Head
                    onBack={handleBack}
                    dispatch={dispatch}
                    fetchingDownload={fetchingDownload}
                    onClickDownloadPDF={() => handleClickDownload("pdf")}
                    onClickDownloadXLS={() => handleClickDownload("xls")}
                    downloadLabel="global.download"
                />
            </ProductDetail>

            <MainContainer showLoader={isFetching || !detail}>
                {detail && (
                    <div className="container-left">
                        <div
                            className={classNames(`title-left-header`, {
                                "echeq--withPendingTransactions": hasPendingTransactions,
                            })}>
                            <I18n id="echeqs.detail.title.label" component="h1" />
                            <div className="data-wrapper ui-mt-3">
                                <EcheqSubStates echeq={detail} isForDetail />
                            </div>
                        </div>
                        {hasPendingTransactions && (
                            <Alert icon="images/ui-icons/ui-exclamation-orange.svg" className="ui-mb-7">
                                <I18n id="echeqs.detail.alert.text" />
                                {` `}
                                <Link to={`/transaction/${detail?.relatedPendingTransactions[0]?.idTransaction}`}>
                                    <I18n id="echeqs.detail.alert.link.text" />
                                </Link>
                            </Alert>
                        )}
                        <ProductDetail>
                            <div className="details-container">
                                <Row className="details__bg-content">
                                    <Col sm={12} md={6}>
                                        <div className="data-list inline">
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.issuingBank.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.emisor_banco_nombre}</span>
                                            </div>

                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.checkNumber.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.cheque_numero}</span>
                                            </div>

                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.amount.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data" />
                                                <FormattedAmount
                                                    currency={detail?.emisor_moneda}
                                                    quantity={detail?.monto}
                                                />
                                            </div>

                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.character.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.cheque_caracter}</span>
                                            </div>

                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.beneficiaryName.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.beneficiario_nombre}</span>
                                            </div>

                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.cuit.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.beneficiario_documento}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6} className={isMobile ? "ui-mt-3" : ""}>
                                        <div className="data-list inline">
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.issueDate.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <FormattedDate date={detail?.fecha_emision} dateFormat="DD/MM/YYYY" />
                                            </div>

                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.paymentDate.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <FormattedDate date={detail?.fecha_pago} dateFormat="DD/MM/YYYY" />
                                            </div>

                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.businessName.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.emisor_razon_social}</span>
                                            </div>

                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.cuit.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.emisor_cuit}</span>
                                            </div>

                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.CMC7.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.cmc7}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="echeq-actions--container">
                                <EcheqActions
                                    dispatch={dispatch}
                                    echeqType={echeqType}
                                    idActivity={ID_ACTIVITY}
                                    echeq={detail}
                                    activeEnvironment={activeEnvironment}
                                    isMobile={isMobile}
                                    hasPendingTransactions={hasPendingTransactions}
                                />

                                <Button
                                    bsStyle="link"
                                    onClick={() => handleCollapse()}
                                    label={`echeqs.detail.moreInformation.${collapse ? "open" : "close"}.title.label`}
                                    className="btn-filter"
                                    image={`images/${collapse ? "selectArrowDown.svg" : "selectArrowUpBlack.svg"}`}
                                />
                            </div>
                            <Row className={`echeq-collapsable-panel ${collapse ? "" : "open"}`}>
                                <Col sm={12} md={6}>
                                    <div className="data-list inline">
                                        {detail?.emisor_cbu && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.cbu.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.emisor_cbu}</span>
                                            </div>
                                        )}

                                        {detail?.emisor_cuenta && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.issuingAccount.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.emisor_cuenta}</span>
                                            </div>
                                        )}

                                        {detail?.emisor_domicilio && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.issuingDomicile.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.emisor_domicilio}</span>
                                            </div>
                                        )}

                                        {detail?.emisor_cp && (
                                            <>
                                                <div className="data-wrapper">
                                                    <I18n
                                                        id="echeqs.detail.moreInformation.postalCode.label"
                                                        componentProps={{ className: "data-label" }}
                                                    />
                                                    <span className="head-data">{detail?.emisor_cp}</span>
                                                </div>
                                                <br />
                                            </>
                                        )}

                                        {detail?.numero_chequera && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.checkbookNumber.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.numero_chequera}</span>
                                            </div>
                                        )}

                                        {detail?.cheque_id && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.checkId.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.cheque_id}</span>
                                            </div>
                                        )}

                                        {detail?.cheque_tipo && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.checkType.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <I18n
                                                    id={`echeqs.detail.moreInformation.checkType.${detail?.cheque_tipo}.value`}
                                                    componentProps={{ className: "head-data" }}
                                                />
                                            </div>
                                        )}

                                        {detail?.cheque_caracter && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.character.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.cheque_caracter}</span>
                                            </div>
                                        )}

                                        {detail?.cheque_modo !== null && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.mode.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">
                                                    {detail?.cheque_modo === 1 ? (
                                                        <I18n id="echeqs.detail.moreInformation.mode.crossed" />
                                                    ) : (
                                                        <I18n id="echeqs.detail.moreInformation.mode.notCrossed" />
                                                    )}
                                                </span>
                                            </div>
                                        )}

                                        {detail?.nro_lote !== null && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.batchNumber.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.nro_lote}</span>
                                            </div>
                                        )}

                                        {detail?.agrupador_id && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.bundlerId.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.agrupador_id}</span>
                                            </div>
                                        )}

                                        {detail?.codigo_emision && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.emissionCode.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.codigo_emision}</span>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col sm={12} md={6} className={isMobile ? "ui-mt-3" : ""}>
                                    <div className="data-list inline">
                                        {detail?.emisor_banco_nombre && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.issuingBank.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.emisor_banco_nombre}</span>
                                            </div>
                                        )}

                                        {detail?.emisor_sucursal_nombre && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.branch.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.emisor_sucursal_nombre}</span>
                                            </div>
                                        )}

                                        {detail?.emisor_sucursal_domicilio && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.home.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.emisor_sucursal_domicilio}</span>
                                            </div>
                                        )}

                                        {detail?.emisor_sucursal_cp && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.postalCode.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.emisor_sucursal_cp}</span>
                                            </div>
                                        )}

                                        {detail?.emisor_sucursal_provincia && (
                                            <>
                                                <div className="data-wrapper">
                                                    <I18n
                                                        id="echeqs.detail.moreInformation.province.label"
                                                        componentProps={{ className: "data-label" }}
                                                    />
                                                    <span className="head-data">
                                                        {detail?.emisor_sucursal_provincia}
                                                    </span>
                                                </div>
                                                <br />
                                            </>
                                        )}

                                        {detail?.cheque_concepto && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.concept.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <I18n
                                                    id={`forms.transfers.concepts.${detail.cheque_concepto}.label`}
                                                    defaultValue={detail.cheque_concepto}
                                                    componentProps={{ className: "head-data" }}
                                                />
                                            </div>
                                        )}

                                        {detail?.cheque_motivo_pago && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.paymentReason.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.cheque_motivo_pago}</span>
                                            </div>
                                        )}

                                        {detail?.cod_visualizacion && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.displayCode.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.cod_visualizacion}</span>
                                            </div>
                                        )}

                                        {detail?.referencia && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.reference.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.referencia}</span>
                                            </div>
                                        )}

                                        {detail?.fecha_ult_modif && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.lastModificationDate.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <FormattedDate date={detail?.fecha_ult_modif} dateFormat="DD/MM/YYYY" />
                                            </div>
                                        )}

                                        {detail?.motivo_repudio_emision && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.repudiationReason.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.motivo_repudio_emision}</span>
                                            </div>
                                        )}

                                        {detail?.motivo_anulacion && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="echeqs.detail.moreInformation.cancellationReason.label"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="head-data">{detail?.motivo_anulacion}</span>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>

                            <ProductDetail.Body fetching={isFetching} isDesktop={false} filters={null}>
                                <DetailItem
                                    section="signers"
                                    items={detail?.firmantes}
                                    keys={["documento_tipo", "documento"]}
                                    labels={["documentType", "documentNumber"]}
                                    position={0}
                                    totalItems={detail?.total_firmantes}
                                    title="echeqs.detail.section.signers.title.label"
                                    isMobile={isMobile}
                                />

                                <DetailItem
                                    section="refund"
                                    items={[{ cuit: detail?.cuit_solic_devol }]}
                                    keys={["cuit"]}
                                    labels={["cuit"]}
                                    position={1}
                                    totalItems={detail?.cuit_solic_devol !== "" ? 1 : 0}
                                    title="echeqs.detail.section.refund.title.label"
                                    isMobile={isMobile}
                                />

                                <DetailItemForEndorsement
                                    section="endorsement"
                                    items={detail?.endosos}
                                    labels={["date", "type", "beneficiary", "reference", "transmitter", "status"]}
                                    position={2}
                                    totalItems={detail?.total_endosos}
                                    title="echeqs.detail.section.endorsement.title.label"
                                    isMobile={isMobile}
                                />

                                <DetailItemForAssignment
                                    section="assignment"
                                    items={detail?.cesiones}
                                    labels={["date", "id", "assignor", "assignee", "entity", "status"]}
                                    position={3}
                                    totalItems={detail?.total_cesiones}
                                    title="echeqs.detail.section.assignment.title.label"
                                    isMobile={isMobile}
                                />

                                <DetailItemForAval
                                    section="guarantor"
                                    items={detail?.avalistas}
                                    labels={["date", "businessName", "subject", "amount", "entity", "status"]}
                                    position={4}
                                    totalItems={detail?.totalAvalistas}
                                    title="echeqs.detail.section.guarantor.title.label"
                                    isMobile={isMobile}
                                    currency={detail?.emisor_moneda}
                                />

                                <DetailItem
                                    section="rejection"
                                    items={detail?.rechazos}
                                    keys={["fecha_hora", "codigo_rechazo", "motivo_rechazo"]}
                                    labels={["date", "code", "reason"]}
                                    position={5}
                                    totalItems={detail?.total_rechazos}
                                    title="echeqs.detail.section.rejection.title.label"
                                    isMobile={isMobile}
                                />

                                <SeuoLabel />
                            </ProductDetail.Body>
                        </ProductDetail>
                    </div>
                )}
            </MainContainer>
        </>
    );
};

EcheqDetail.propTypes = {
    dispatch: func.isRequired,
    history: func.isRequired,
    activeEnvironment: shape({}).isRequired,
    isFetching: bool.isRequired,
    detail: shape({}).isRequired,
    filters: shape({}).isRequired,
    fetchingDownload: func.isRequired,
    isMobile: bool,
    match: shape({}).isRequired,
    echeqSubStates: shape(),
};

EcheqDetail.defaultProps = {
    isMobile: false,
    echeqSubStates: null,
};

const mapPropsToValue = (state) => ({
    isFetching: eCheqsSelector.getFetching(state),
    detail: eCheqsSelector.getEcheqDetail(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    filters: eCheqsSelector.getFilters(state),
    fetchingDownload: eCheqsSelector.getFetchingDownload(state),
    echeqSubStates: sessionSelectors.getEcheqSubStates(state),
});

export default connect(mapPropsToValue)(EcheqDetail);
