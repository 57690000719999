// Action types
export const types = {
    REQUEST_DOCUMENT_TYPES: "misc/REQUEST_DOCUMENT_TYPES",
    REQUEST_DOCUMENT_TYPES_SUCCESS: "misc/REQUEST_DOCUMENT_TYPES_SUCCESS",
    REQUEST_JUMP: "misc/REQUEST_JUMP",
    ECHEQ_JUMP_SUCCESS: "accounts/ECHEQ_JUMP_SUCCESS",
    ECHEQ_JUMP_REQUEST: "accounts/ECHEQ_JUMP_REQUEST",
    ECHEQ_JUMP_FAILURE: "accounts/ECHEQ_JUMP_FAILURE",
    REQUEST_DIGITAL_SIGNATURE: "misc/REQUEST_DIGITAL_SIGNATURE",
    REQUEST_CERTIFICATE_CALFCEM: "misc/REQUEST_CERTIFICATE_CALFCEM",
    REQUEST_MODAL_INVOICEMIPYMECONSULTATION: "misc/REQUEST_MODAL_INVOICEMIPYMECONSULTATION",
    RENDER_MODAL_INVOICEMIPYMECONSULTATION: "misc/RENDER_MODAL_INVOICEMIPYMECONSULTATION",
    CEDIP_JUMP_REQUEST: "misc/CEDIP_JUMP_REQUEST"
};

// Initial state
export const INITIAL_STATE = {
    documentTypes: [],
    filter: null,
    modalInvoiceMipymeConsultation: false,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.REQUEST_DOCUMENT_TYPES_SUCCESS:
            return { ...state, documentTypes: action.documentTypes, filter: action.filter };
        case types.RENDER_MODAL_INVOICEMIPYMECONSULTATION:
            return { ...state, modalInvoiceMipymeConsultation: action.isOpen };
        case types.ECHEQ_JUMP_REQUEST:
            return { ...state, fetchingAccount: true };
        case types.ECHEQ_JUMP_SUCCESS:
        case types.ECHEQ_JUMP_FAILURE:
            return { ...state, fetchingAccount: false };
        default:
            return state;
    }
};

// Action creators
export const actions = {
    getDocumentTypes: (filter) => ({
        type: types.REQUEST_DOCUMENT_TYPES,
        filter,
    }),
    recoveryUserCleanUp: () => ({
        type: types.CLEAN,
    }),
    getJumpUrl: () => ({
        type: types.REQUEST_JUMP,
    }),
    getEcheqUrl: () => ({
        type: types.ECHEQ_JUMP_REQUEST,
    }),
    getDigitalSignature: () => ({
        type: types.REQUEST_DIGITAL_SIGNATURE,
    }),
    getCertificateCalfCem: () => ({
        type: types.REQUEST_CERTIFICATE_CALFCEM,
    }),
    getInvoiceMipymeConsultationUrl: () => ({
        type: types.REQUEST_MODAL_INVOICEMIPYMECONSULTATION,
    }),
    setModalInvoiceMipymeConsultation: (isOpen) => ({
        type: types.RENDER_MODAL_INVOICEMIPYMECONSULTATION,
        isOpen,
    }),
    getCedipUrl: () => ({
        type: types.CEDIP_JUMP_REQUEST,
    }),
};

// Selectors
export const selectors = {
    getDocumentTypes: (state) => state.misc.documentTypes,
    getDocumentFilter: (state) => state.misc.filter,
    getModalInvoiceMipymeConsultation: (state) => state.misc.modalInvoiceMipymeConsultation,
};
