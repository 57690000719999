import React from "react";
import { bool, func, instanceOf, oneOfType } from "prop-types";
import moment from "moment";

import { MainContainerContext } from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import CustomDatePicker from "pages/_components/fields/datepicker/CustomDatePicker";
import MobileCustomDatePickerInput from "pages/_components/fields/datepicker/MobileCustomDatePickerInput";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";

class DatePickerWithCustomInput extends React.Component {
    startRef = React.createRef();

    static propTypes = {
        isMobile: bool.isRequired,
        buttonSelect: bool,
        filter: func,
        selected: oneOfType([instanceOf(Date), instanceOf(moment)]),
        onChange: func,
        onFocus: func,
        disabled: bool,
        exceptionDatePicker: bool,
        availableAllDayofWeek: bool,
        defaultValue: instanceOf(moment),
        minDate: instanceOf(moment),
        maxDate: instanceOf(moment),
    };

    static defaultProps = {
        buttonSelect: false,
        filter: null,
        selected: new Date(),
        onChange: null,
        onFocus: null,
        disabled: false,
        exceptionDatePicker: false,
        availableAllDayofWeek: false,
        defaultValue: null,
        minDate: null,
        maxDate: null,
    };

    constructor(props) {
        super(props);
        const { selected } = this.props;
        this.state = {
            value: selected,
            select: selected,
        };
    }

    setValue = () => {
        const { onChange, onFocus } = this.props;
        const { select } = this.state;

        this.setState((before) => ({
            ...before,
            value: before.select,
        }));

        onChange(select);
        this.startRef.current.setOpen(false);
        onFocus();
    };

    closeCalendar = () => {
        this.startRef.current.setOpen(false);
    };

    cancelValue = () => {
        const { onFocus } = this.props;

        this.setState((before) => ({
            ...before,
            select: before.value,
        }));

        this.closeCalendar();
        onFocus();
    };

    onKeyDown = (e) => {
        const { onChange } = this.props;

        e.preventDefault();
        if (e.keyCode === 9 || e.which === 9) {
            this.closeCalendar();
        }

        if (e.keyCode === 8 || e.which === 8 || e.keyCode === 46 || e.which === 46) {
            onChange(null);
        }
    };

    isWeekday = (date) => {
        const day = new Date(date).getDay();
        return day !== 0 && day !== 6;
    };

    onChange = (value) => {
        const { onChange, buttonSelect } = this.props;

        if (onChange && !buttonSelect) {
            onChange(value);
        }

        this.setState((before) => ({
            ...before,
            select: value,
        }));
    };

    formatMonth = (month) => {
        if (String(month).length === 1) {
            return `0${month}`;
        }
        return String(month);
    };

    getHeaderCalendar = ({
        date,
        decreaseMonth,
        increaseMonth,
        changeYear,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => {
        const { minDate, maxDate } = this.props;

        const fecha = new Date(String(date._d));
        const month = new Intl.DateTimeFormat(i18n.getLang(), { month: "long" }).format(fecha);
        const monthNumber = fecha.getMonth();
        const year = fecha.getFullYear();

        const formatedDates = {
            minDate: Number(String(minDate?._d.getFullYear()) + this.formatMonth(minDate?._d.getMonth() + 1)),
            maxDate: Number(String(maxDate?._d.getFullYear()) + this.formatMonth(maxDate?._d.getMonth() + 1)),
            date: Number(String(year) + this.formatMonth(monthNumber + 1)),
            oneYearBeforeDate: Number(String(year - 1) + this.formatMonth(monthNumber + 1)),
            oneYearAfterDate: Number(String(year + 1) + this.formatMonth(monthNumber + 1)),
        };

        const showPrevYearBtn = formatedDates.minDate <= formatedDates.oneYearBeforeDate;
        const showNextYearBtn = formatedDates.maxDate >= formatedDates.oneYearAfterDate;

        return (
            <div className="react-datepicker__header-arrows">
                <button
                    type="button"
                    disabled={prevMonthButtonDisabled || !showPrevYearBtn}
                    onClick={() => changeYear(year - 1)}
                    className="react-datepicker__navigation react-datepicker__navigation--previous-double">
                    {"<<"}
                </button>
                <button
                    type="button"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    className="react-datepicker__navigation react-datepicker__navigation--previous">
                    {"<"}
                </button>
                <div className="react-datepicker__current-month">{`${month}  ${year}`}</div>
                <button
                    type="button"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    className="react-datepicker__navigation react-datepicker__navigation--next">
                    {">"}
                </button>
                <button
                    type="button"
                    disabled={nextMonthButtonDisabled || !showNextYearBtn}
                    onClick={() => changeYear(year + 1)}
                    className="react-datepicker__navigation react-datepicker__navigation--next-double">
                    {">>"}
                </button>
            </div>
        );
    };

    getFooterButtons = () => (
        <div className="react-datepicker-buttons">
            <Button
                className="react-datepicker-button btn btn-outline"
                bsStyle={["outline btn-small"]}
                onClick={this.cancelValue}>
                {i18n.get("global.cancel")}
            </Button>
            <Button className="react-datepicker-button btn" bsStyle={["primary btn-small"]} onClick={this.setValue}>
                {i18n.get("global.aplly")}
            </Button>
        </div>
    );

    render() {
        const {
            isMobile,
            selected,
            disabled,
            exceptionDatePicker,
            availableAllDayofWeek,
            buttonSelect,
            filter,
        } = this.props;
        if (isMobile && !exceptionDatePicker) {
            return (
                <MainContainerContext.Consumer>
                    {(ref) => (
                        <CustomDatePicker
                            {...this.props}
                            isMobile={isMobile}
                            ref={this.startRef}
                            onKeyDown={(e) => e.preventDefault()}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            filterDate={availableAllDayofWeek ? "" : filter || this.isWeekday}
                            formatWeekDay={(nameOfDay) => nameOfDay[0].toLocaleUpperCase()}
                            renderCustomHeader={this.getHeaderCalendar}
                            shouldCloseOnSelect={!buttonSelect}
                            onChange={this.onChange}
                            selected={selected}
                            locale={i18n.getLang()}
                            customInput={<MobileCustomDatePickerInput onChange={this.onChange} />}
                            viewContentRef={ref}
                            disabled={disabled}>
                            {buttonSelect && this.getFooterButtons()}
                        </CustomDatePicker>
                    )}
                </MainContainerContext.Consumer>
            );
        }

        return (
            <CustomDatePicker
                {...this.props}
                isMobile={isMobile}
                ref={this.startRef}
                onKeyDown={this.onKeyDown}
                isClearable={false}
                dateFormat="DD/MM/YYYY"
                filterDate={availableAllDayofWeek ? null : filter || this.isWeekday}
                formatWeekDay={(nameOfDay) => nameOfDay[0].toLocaleUpperCase()}
                renderCustomHeader={this.getHeaderCalendar}
                shouldCloseOnSelect={!buttonSelect}
                onChange={this.onChange}
                selected={selected}
                locale={i18n.getLang()}>
                {buttonSelect && this.getFooterButtons()}
            </CustomDatePicker>
        );
    }
}

export default resizableRoute(DatePickerWithCustomInput);
