import { takeLatest, call, put, select } from "redux-saga/effects";

import { types, selectors, actions } from "reducers/misc";

import * as miscMiddleware from "middleware/misc";

import { actions as notificationActions } from "reducers/notification";

import * as i18n from "util/i18n";
import { isMobileNative } from "util/device";
import { openLink } from "util/browser";

const sagas = [
    takeLatest(types.REQUEST_DOCUMENT_TYPES, handleRequestDocumentTypes),
    takeLatest(types.REQUEST_JUMP, handleJump),
    takeLatest(types.ECHEQ_JUMP_REQUEST, getEcheqUrl),
    takeLatest(types.REQUEST_DIGITAL_SIGNATURE, handleDigitalSignature),
    takeLatest(types.REQUEST_MODAL_INVOICEMIPYMECONSULTATION, handleInvoiceMipymeConsultation),
    takeLatest(types.REQUEST_CERTIFICATE_CALFCEM, handleCertificateCalfCem),
    takeLatest(types.CEDIP_JUMP_REQUEST, getCedipUrl),
];

export default sagas;

function* handleCertificateCalfCem() {
    const response = yield call(miscMiddleware.getCertificateCalfCemUrl);
    const { url } = response.data.data;
    if (isMobileNative) {
        openLink(url).then();
    } else {
        window.open(url, i18n.get("window.name.certificateCalfCem"));
    }
}

function* handleInvoiceMipymeConsultation() {
    const response = yield call(miscMiddleware.getInvoiceMipymeConsultationUrl);
    const { url } = response.data.data;
    if (isMobileNative) {
        openLink(url).then();
    } else {
        window.open(url, i18n.get("window.name.invoiceMipymeConsultation"));
    }
    yield put(actions.setModalInvoiceMipymeConsultation(false));
}

function* handleJump() {
    const response = yield call(miscMiddleware.getJumpUrl);
    const { url } = response.data.data;
    if (isMobileNative) {
        openLink(url).then();
    } else {
        window.open(url, i18n.get("window.name.position"));
    }
}

function* getEcheqUrl() {
    const response = yield call(miscMiddleware.getEcheqUrl);
    if (response && response.status === 200) {
        const { url } = response.data.data;
        yield put({ type: types.ECHEQ_JUMP_SUCCESS });
        window.open(url, i18n.get("window.name.echeq", "eCheq"));
    } else {
        yield put({ type: types.ECHEQ_JUMP_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["menu"]));
    }
}

function* handleRequestDocumentTypes({ filter }) {
    const actualFilter = yield select(selectors.getDocumentFilter);
    let docTypesList = yield select(selectors.getDocumentTypes);

    if (docTypesList === null || docTypesList.length === 0 || filter !== actualFilter) {
        const response = yield call(miscMiddleware.getDocumentTypes, filter);
        docTypesList = response.data.data.documentTypeList;
    }
    yield put({ type: types.REQUEST_DOCUMENT_TYPES_SUCCESS, documentTypes: docTypesList, filter });
}

function* handleDigitalSignature() {
    const response = yield call(miscMiddleware.getDigitalSignatureUrl);
    const { url } = response.data.data;
    if (isMobileNative) {
        openLink(url).then();
    } else {
        window.open(url, i18n.get("window.name.digitalSignature"));
    }
}

function* getCedipUrl() {
    const response = yield call(miscMiddleware.getCedipUrl);
        const { url } = response.data.data;
        window.open(url, i18n.get("window.name.cedip.jump"));
}
