export const isString = (value) => typeof value === "string";

export const isEmpty = (value) => isString(value) && (value.trim() === "" || value.trim() === "_empty");

export const trim = (value) => {
    if (isString(value)) {
        return value.trim();
    }

    return value;
};

export const capitalizeFirstLetter = (string) => (string ? string.charAt(0).toUpperCase() + string.slice(1) : "");

export const insertLinebreaks = (string) => string.replace(/<br ?\/?>/g, "\n");

export const insertHTMLLinebreaks = (string) => string.replace(/\n/g, "<br>");

export const returnTypeAndNumberDocument = (cuit, number) =>
    cuit
        ?.toUpperCase()
        .concat(" ")
        .concat(number);

export const formatCuit = (cuit) => {
    if (cuit?.length === 11) {
        return cuit
            .substring(0, 2)
            .concat("-")
            .concat(cuit.substring(2, 10))
            .concat("-")
            .concat(cuit.substring(10));
    }
    return cuit;
};

export const getKeyForSupplierValidation = (paymentType) => {
    let keyDetailForValidation = "";
    if (paymentType === "transfer") {
        keyDetailForValidation = "transferNumber";
    } else if (paymentType === "check") {
        keyDetailForValidation = "checkNumber";
    } else if (paymentType === "echeck") {
        keyDetailForValidation = "eCheckNumber";
    }

    return keyDetailForValidation;
};
